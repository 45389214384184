import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import mumbai from '../../../assets/img/shop/mumbai.svg';
import delhi from '../../../assets/img/shop/delhi.svg';
import banglore from '../../../assets/img/shop/banglore.svg';
import kolkata from '../../../assets/img/shop/kolkata.svg';
import chennai from '../../../assets/img/shop/chennai.svg';
import pune from '../../../assets/img/shop/pune.svg';


class Shopinfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
            clicks: 1,
            show: true,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }
    IncrementItem = () => {
        this.setState({ clicks: this.state.clicks + 1 });
    };

    DecreaseItem = () => {
        if (this.state.clicks < 1) {
            this.setState({
                clicks: 0,
            });
        } else {
            this.setState({
                clicks: this.state.clicks - 1,
            });
        }
    };
    handleChange(event) {
        this.setState({ clicks: event.target.value });
    }
    render() {

        return (
            <section className="Shop-section mt-100 mb-80">
                <div className="container">
                    <div className='col-12'>
                        <div className="row justify-content-center">
                            <div className='col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-6'>
                                <Link to="">
                                    <img src={mumbai} alt="img" />
                                    <h4 className='pt-1 text-center text-dark'>Mumbai</h4>
                                    <p className='text-center text-dark'>4 Stores</p>
                                </Link>
                            </div>
                            <div className='col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-6'>
                                <Link to="">
                                    <img src={delhi} alt="img" />
                                    <h4 className='pt-1 text-center text-dark'>Delhi</h4>
                                    <p className='text-center text-dark'>4 Stores</p>
                                </Link>
                            </div>
                            <div className='col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-6'>
                                <Link to="">
                                    <img src={banglore} alt="img" />
                                    <h4 className='pt-1 text-center text-dark'>Banglore</h4>
                                    <p className='text-center text-dark'>4 Stores</p>
                                </Link>
                            </div>
                            <div className='col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-6'>
                                <Link to="">
                                    <img src={chennai} alt="img" />
                                    <h4 className='pt-1 text-center text-dark'>Chennai</h4>
                                    <p className='text-center text-dark'>4 Stores</p>
                                </Link>
                            </div>
                            <div className='col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-6'>
                                <Link to="">
                                    <img src={pune} alt="img" />
                                    <h4 className='pt-1 text-center text-dark'>Pune</h4>
                                    <p className='text-center text-dark'>4 Stores</p>
                                </Link>
                            </div>
                            <div className='col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-6'>
                                <Link to="">
                                    <img src={kolkata} alt="img" />
                                    <h4 className='pt-1 text-center text-dark'>Kolkata</h4>
                                    <p className='text-center text-dark'>4 Stores</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* <div className='container row justify-content-center mb-90'>
                            <h1>In Store Services</h1>
                        </div> */}
                    {/* <div className="container row justify-content-center pb-120">
                            <div className="col-lg-12 col-md-6">
                                <ul className='d-flex'>
                                    <li className='col-4 p-10'>
                                        <img src={img1} alt="img" />
                                        <h4 className='pt-5 text-center'>Old Gold Exchange</h4>
                                        <p className='text-center pt-3'>It can be any Gold Jewellery</p>
                                    </li>
                                    <li className='col-4'>
                                        <img src={img1} alt="img" />
                                        <h4 className='pt-5 text-center'>Buy Online, Pick-up at Store</h4>
                                        <p className='text-center pt-3'>Save time, Pick-up & Go</p>
                                    </li>
                                    <li className='col-4'>
                                        <img src={img1} alt="img" />
                                        <h4 className='pt-5 text-center'>In Store Customization</h4>
                                        <p className='text-center pt-3'>Made-to-order and Personalized for you</p>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                </div>
            </section>
        );
    }
}

export default Shopinfo;