import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Slider from 'react-slick';
import { Tab, Nav } from 'react-bootstrap';
import Cookies from 'universal-cookie';

import bgimg1 from '../../../assets/img/shop/detail-1.png';
import bgimg2 from '../../../assets/img/shop/detail-2.png';

import smimg1 from '../../../assets/img/shop/s-1.png';
import smimg2 from '../../../assets/img/shop/s-2.png';
import smimg3 from '../../../assets/img/shop/s-3.png';
import smimg4 from '../../../assets/img/shop/s-4.png';

import bis from '../../../assets/img/shop/bsi.png';
import igi from '../../../assets/img/shop/igi.png';
import gold from '../../../assets/img/shop/gold.png';
import silver from '../../../assets/img/shop/silver.png';
import platinum from '../../../assets/img/shop/platinum.png';

import returnicon from '../../../assets/img/icon/return.png';
import buyback from '../../../assets/img/icon/life.png';
import Certified from '../../../assets/img/icon/badge.png';

import popupimg from '../../../assets/img/newsltr.jpg';

// import comment1 from '../../../assets/img/blog-details/avatar-1.jpg';
// import comment2 from '../../../assets/img/blog-details/avatar-2.jpg';
// import comment3 from '../../../assets/img/blog-details/avatar-3.jpg';
import { addCartList } from "../../../slices/customerSlice";
import { connect } from 'react-redux';
import { Modal } from "react-bootstrap";


const bigsliderpost = [
    { img: bgimg1, tag: 'Sale' },
    { img: bgimg2 },
    { img: bgimg2, tag: 'Sale' },
    { img: bgimg2, tag: 'Sale' },
    { img: bgimg1, tag: 'Sale' },
];
const smallsliderpost = [
    { img: smimg1 },
    { img: smimg2 },
    { img: smimg3 },
    { img: smimg4 },
    { img: smimg1 },
    { img: smimg2 },
];

class Shopinfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
            clicks: 1,
            show: true,
            data: [],
            tags: null,
            addedToCart: false,
            goldQuality: '',
            diamondQuality: '',
            stoneList: [],
            isStoneChecked: false,
            // product_stock_status: "MTO",
            customCheck: false,
            isCustom: false,
            productStone: [],
            productPrice: [],
            tablePrice: {},
            productWeight: [],
            productGems: [],
            productImg: [],
            material: '',
            gems: '',
            productItem: {},
            productCustom: null,
            goldColor: '',
            productValue: '',
            gemsCheck:"",
        };
        // this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
        this.fetchData();
        this.getProductDetails();
    }
    // Check if product_stock_status is "MTO"

    checkedCustom = () => {
        this.setState({ isCustom: !this.state.isCustom }, () => {
            // if(this.state.isCustom){
            //     this.setState({productItem:{},productCustom:{}})
            // }
            if (this.state.isCustom === false) {
                this.setState({ productCustom: null })
                this.setState({ data: { ...this.state.data, product_value: this.state.productValue } })
            }
        })

    }
    async getProductDetails() {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/api/admin/other-stones');
        const data = await response.json()

        const stoneItems = data.map((item) => {
            return item.ots_name;
        })

        this.setState({ stoneList: stoneItems })

    }
    handleproductStone = (event) => {
        const id = event.target.value
        this.setState((prevVal) => {
            const { diamondQuality, ...updatedproductItem } = prevVal.productItem;
            return {
                productItem: {
                    ...updatedproductItem, stone: id
                }
            }
        }, () => { this.productMetal() })
        this.setState((preValue) => {
            let updateCustom
            if (preValue.productCustom === null) {
                updateCustom = { gems: this.state.gems, stone: id }
            }
            else {
                const { diamondQuality, ...updatedCustom } = preValue.productCustom
                updateCustom = {
                    ...updatedCustom, gems: this.state.gems, stone: id
                }
            }

            return {
                productCustom: updateCustom
            }
        })
        // this.setState({productCustom:{...this.state.productCustom,gems:this.state.gems,stone:id}})
    }
    showStonelist = (event) => {
        const stoneName = event.target.value
        this.setState({ gemsCheck: true})
        this.setState({ isStoneChecked: true, gems: stoneName })
        this.setState({ dQuality: false })

        // this.setState((preValue)=>{
        //     let updateCustom
        //     if(preValue.productCustom===null){
        //         updateCustom = {gems:stoneName}
        //     }
        //     else{
        //        const {diamondQuality,...updatedCustom} = preValue.productCustom
        //        updateCustom={
        //         ...updatedCustom,gems:stoneName
        //        }
        //     }

        //     return{
        //         productCustom:updateCustom }
        // })
    }
    IncrementItem = () => {
        this.setState({ clicks: this.state.clicks + 1 });
    };

    DecreaseItem = () => {
        if (this.state.clicks > 1) {
            this.setState({
                clicks: this.state.clicks - 1,
            });
        }
    };
    async productMetal() {
        try {
            const { id } = this.props.match.params;
            var response = await fetch(process.env.REACT_APP_API_BASE_URL + `/api/product/getProductById/${id}`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(this.state.productItem)
            })

            var datas = await response.json();
            this.setState({ data: datas.productDetail });
            this.setState({ productPrice: datas.price })
        }
        catch (err) {
            console.log('error', err);
        }

    }

    onRadioChange = (event) => {
        var quality = event.target.value;
        this.setState({ goldQuality: quality })
        this.setState({ productItem: { ...this.state.productItem, purity: quality } }, () => { this.productMetal() })
        this.setState({ productCustom: { ...this.state.productCustom, purity: quality } })
    }
    onDiamondChange = (event) => {
        var quality = event.target.value;
        this.setState({ diamondQuality: quality })

        this.setState((prevVal) => {
            const { stone, ...updatedItem } = prevVal.productItem
            return {
                productItem: {
                    ...updatedItem, diamondQuality: quality
                }
            }
        }, () => { this.productMetal() })

        // this.setState({productCustom:{...this.state.productCustom,diamondQuality:quality}}) 
        this.setState((preValue) => {
            let updateCustom
            if (preValue.productCustom === null) {
                updateCustom = { gems: this.state.gems, diamondQuality: quality }
            }
            else {
                const { stone, ...updatedCustom } = preValue.productCustom
                updateCustom = {
                    ...updatedCustom, gems: this.state.gems, diamondQuality: quality
                }
            }
            return { productCustom: updateCustom }

        })
    }

    handleChange = (event) => {
        const { value } = event.target;
        this.setState({ clicks: value });
    };

    fetchData = async () => {
        try {
            const { id } = this.props.match.params;
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + `/api/product/getProductById/${id}`);
            const responseData = await response.json();
            this.setState({ productValue: responseData.productDetail.product_value })
            this.setState({ data: responseData.productDetail, productImg: responseData.images, productStone: responseData.stones });
            this.setState({ productPrice: responseData.price, productWeight: responseData.weight, productGems: responseData.gems })
            this.setState({
                tablePrice: {
                    milli_stone_price: responseData.productDetail.milli_stone_price,
                    milli_diamond_price: responseData.productDetail.milli_diamond_price,
                    product_price: responseData.productDetail.product_price
                }
            })
            responseData.productDetail.product_stock_status === "MTO" && this.setState({ isCustom: true })
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    handleAddToCart = async (event) => {

        const isLoggedIn = JSON.parse(localStorage.getItem('userData')); // Assuming you have a prop called isLoggedIn indicating whether the user is logged in or not
        const { data, clicks } = this.state;
        this.props.counterIncrease();
        if(this.state.gemsCheck===""){
            this.setState({gemsCheck:false})
        }
        if (isLoggedIn) {
            // let userObj = localStorage.getItem('userData');
            // Send form data to API
            const formData = new FormData();
            formData.append('cart_product_id', data.product_id);
            formData.append('cart_quantity', clicks);
            formData.append('cart_user_id', isLoggedIn.userId);
            formData.append('orderDetails', JSON.stringify(this.state.productCustom))
            console.log(formData.get('orderDetails'))
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/api/cart/add', {
                method: 'POST',
                body: formData,
            })
                .then(response => {
                    if (response.ok) {
                        // Item added to cart successfully
                        this.setState({ addedToCart: true });
                        this.updateCardCount();
                    } else {
                        // Handle error response
                    }
                })
                .catch(error => {
                    console.error('Error adding item to cart:', error);
                });
        } else {
            // Store form data in cookies
            const cookies = new Cookies();
            let cartItems = cookies.get('cartItems') || [];
            // Check if the product already exists in the cartItems
            const existingProductIndex = cartItems.findIndex(item => item.productId === data.product_id);
            if (existingProductIndex !== -1) {
                // Product already exists, update its quantity
                cartItems[existingProductIndex].quantity += clicks;
            } else {
                // Product does not exist, add it to cartItems
                cartItems.push({ productId: data.product_id, quantity: clicks });
            }
            cookies.set('cartItems', cartItems);

            // Update state to indicate item added to cart
            this.setState({ addedToCart: true });
            this.updateCardCount();

            // cookies.remove('cartItems');
            const decodedCookies = decodeURIComponent(document.cookie);
            console.log(decodedCookies);

        }
        event.preventDefault();
    };
    
    updateCardCount = () => {
        const isLoggedIn = JSON.parse(localStorage.getItem('userData'));
        if (isLoggedIn) {
            this.fetchDataCart(isLoggedIn.userId);
        } else {
            this.getCookiesCartList();
        }
    }

    fetchDataCart = async (userIdControl) => {
        try {
            if (!userIdControl) userIdControl = '1';
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + `/api/cart/find-by-user-id/${userIdControl}`);
            const data = await response.json();
            let totalPriceDtl = 0;
            data.forEach(element => {
                element['totalMultiplyPrice'] = Number(element.product_price) * Number(element.quantity);
                totalPriceDtl = totalPriceDtl + element.totalMultiplyPrice;
            });

            this.props.addCartList(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    getCookiesCartList() {
        const cookies = new Cookies();
        let cartItems = cookies.get('cartItems') || [];
        let idControl = '';
        cartItems.forEach(element => {
            idControl = idControl + ',' + element.productId;
        });
        // this.setState({ cartItems });
        if (cartItems.length > 0) {
            this.getProductListById(idControl.slice(1, idControl.length), cartItems);
        }
    }

    getProductListById = async (idControl, cartItems) => {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + `/api/product/get_product_detail?cartList=${idControl}`, {
            method: 'POST',
            // body: apiData
        });
        const dataList = await response.json();
        let totalPriceDtl = 0;
        dataList.forEach(element => {
            let quantityControl = cartItems?.filter((data) => {
                return data.productId == element.product_id
            });
            element['totalMultiplyPrice'] = Number(element.product_price) * Number(quantityControl[0]?.quantity);
            totalPriceDtl = totalPriceDtl + element.totalMultiplyPrice;
        });
        this.props.addCartList(dataList);
    }

    metalColor = (event) => {
        const goldcolor = event.target.value;
        this.setState({ goldColor: goldcolor });
        this.setState({ productCustom: { ...this.state.productCustom, color: goldcolor } })
    }
    showDiamondQuality = (event) => {
        this.setState({ gemsCheck: true})
        this.setState({ dQuality: !this.state.dQuality });
        this.setState({ isStoneChecked: false, gems: event.target.value });
        // this.setState({productCustom:{...this.state.productCustom,gems:event.target.value}})
        // this.handleCheckboxChange("Other");
    }

    showGoldQuality = (event) => {
        this.setState({ gQuality: !this.state.gQuality });
        // this.handleCheckboxChange("Other");
        this.setState({ isStoneChecked: false, material: event.target.value });
        this.setState({ productItem: { ...this.state.productItem, metal: event.target.value } })
        this.setState({ productCustom: { ...this.state.productCustom, metal: event.target.value } })
    }
    showSilverQuality = (event) => {
        this.setState({ gQuality: false, material: event.target.value })
        this.setState({ productItem: { ...this.state.productItem, metal: event.target.value } }, () => { this.productMetal() })
        // this.setState({productCustom:{...this.state.productCustom,metal:event.target.value}})
        this.setState((preVal) => {
            let updateCustom
            if (preVal.productCustom === null) {
                updateCustom = { metal: event.target.value }
            }
            else {
                const { color, ...updatedCustom } = preVal.productCustom
                updateCustom = { ...updatedCustom, metal: event.target.value }
            }

            return {
                productCustom: updateCustom
            }
        })
    }

    showPlatinumQuality = (event) => {
        this.setState({ gQuality: false, material: event.target.value })
        this.setState({ productItem: { ...this.state.productItem, metal: event.target.value } }, () => { this.productMetal() })
        // this.setState({productCustom:{...this.state.productCustom,metal:event.target.value}})

        this.setState((preVal) => {
            let updateCustom;
            if (preVal.productCustom === null) {
                updateCustom = { metal: event.target.value }
            }
            else {
                const { color, ...updatedCustom } = preVal.productCustom
                updateCustom = { ...updatedCustom, metal: event.target.value }
            }
            return {
                productCustom: updateCustom
            }
        })
    }
    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }
    showModal = () => {
        this.setState({ modal: !this.state.modal });
        // this.handleCheckboxChange("Other");
    }

    render() {
        const { data, productGems, productPrice, productWeight, clicks } = this.state;
        const tags = data?.product_tags?.split(',');
        const tagListItems = tags?.map((tag, index) => (
            <li key={index} className="btn list-inline-item mr-2">
                <Link to="#" className="grey">{tag.trim()}</Link>
            </li>
        ));
        const settings = {
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: false,
            infinite: true,
            autoplay: false,
            arrows: false,
            dots: false,
        };
        const settings2 = {
            slidesToShow: 5,
            slidesToScroll: 1,
            fade: false,
            infinite: true,
            autoplay: false,
            arrows: false,
            dots: false,
            focusOnSelect: true,
            responsive: [{
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                },
            },
            ],
        };
        return (
            <section className="Shop-section pt-120 pb-120">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 mt-5">
                            <div className="shop-detail-image">
                                <Slider className="detail-slider-1" {...settings} asNavFor={this.state.nav2} ref={slider => (this.slider1 = slider)}>
                                    <div className="slide-item">
                                        <div className="image-box">
                                            <Link to="#">
                                                <img src={data.product_parent_image} className="img-fluid" alt="img" />
                                                <p className='discl mt-3'>Actual product may vary from the image show</p>
                                            </Link>
                                        </div>
                                    </div>
                                </Slider>
                                <span className="rating mb-20">
                                    <span className="tag">Certified by:</span>
                                    <span className="pro-review ml-3"><img src={bis} alt='symbol' /></span>
                                    <span className="pro-review ml-3"><img src={igi} alt='symbol' /></span>
                                    <span className="pro-review ml-3"><img src={gold} alt='symbol' /></span>
                                    <span className="pro-review ml-3"><img src={silver} alt='symbol' /></span>
                                    <span className="pro-review ml-3"><img src={platinum} alt='symbol' /></span>
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-7 mt-5">
                            <div className="shop-detail-content">
                                <h3 className="product-title mb-2">{data.product_name}</h3>
                                <div className='rating-stock'>
                                    <div className="other-info flex mb-2">
                                        <h6>Category:</h6>
                                        <ul>
                                            <li className="list-inline-item mr-2">
                                                <Link to="#" className="grey">{data.product_category}</Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <span className="rating mb-10">
                                        <span className="text-dark-white"><i className="far fa-star" /></span>
                                        <span className="text-dark-white"><i className="far fa-star" /></span>
                                        <span className="text-dark-white"><i className="far fa-star" /></span>
                                        <span className="text-dark-white"><i className="far fa-star" /></span>
                                        <span className="text-dark-white"><i className="far fa-star" /></span>
                                        <span className="pro-review"> <span>0 Reviews</span>
                                        </span>
                                        {/* <span className="pro-review ml-3"><img src={bis} /></span>
                                    <span className="pro-review"><img src={igi} /></span>
                                    <span className="pro-review"><img src={gold} /></span>
                                    <span className="pro-review"><img src={silver} /></span>
                                    <span className="pro-review"><img src={platinum} /></span> */}
                                    </span>
                                </div>
                                <div className="mt-7 mb-7">
                                    <div className="d-inline-block other-info">
                                        <h6>Availability:<span className="text-success ml-2">In Stock</span></h6>
                                    </div>
                                    <div className="ml-2 d-inline-block other-info">
                                        <h6>SKU:<span className="grey ml-2">{data.product_sku}</span></h6>
                                    </div>
                                </div>

                                <div className="desc mb-7 pb-7 border-bottom">
                                    <span className="price">₹ {data.product_value}</span>
                                </div>
                                <div className="col-12">
                                    <div className='shop-pin d-flex justify-content-start align-items-start  mb-3'>
                                        {/* <div className=''>
                                    <p className="page-title">Your Pincode</p>
                                </div> */}
                                        <div className="widget search-widget">
                                            <form className='d-flex align-items-start mt-10' action="#">
                                                <input className='pin-input' type="text" placeholder="Pincode" />
                                                <button type="submit">Update</button>
                                            </form>
                                        </div>
                                        <div className=' ml-5 mt-10'>
                                            <p>Provide pincode for delivery date & nearby stores!</p>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="mt-20 mb-20">
                                    <div className="d-inline-block other-info">
                                        <h6>Availability:<span className="text-success ml-2">In Stock</span></h6>
                                    </div>
                                    <div className="ml-2 d-inline-block other-info">
                                        <h6>SKU:<span className="grey ml-2">{data.product_sku}</span></h6>
                                    </div>
                                </div> */}
                                <div className="short-descr mb-10">
                                    <p>{data.product_tags}</p>
                                </div>
                                {data.product_stock_status === "MTO" ? "" :
                                    (this.state.isCustom ? " " :
                                        <div className='category-data'>
                                            <div className='gold-data'>
                                                <div className='metal cat-data'>
                                                    <p className='heading'>Metal:</p>
                                                    <p className='value'>{data.product_type}, {data.product_color}</p>
                                                </div>
                                                <div className='carat cat-data'>
                                                    <p className='heading1'>Carat:</p>
                                                    <p className='value1'>{data.product_purity} kt</p>
                                                </div>
                                            </div>

                                            <div className='weight cat-data'>
                                                <p className='heading'>Weight:</p>
                                                <p className='value'>{productWeight.metal_weight} cent</p>
                                            </div>
                                            {this.state.data.diamond === "1" ?
                                                <>
                                                    <div className='diamond-data'>
                                                        <div className='diamond cat-data'>
                                                            <p className='heading'>Diamond:</p>
                                                            <p className='value'>{data.product_dimond_category}</p>
                                                        </div>
                                                        <div className='diamond-num cat-data'>
                                                            <p className='heading2'>No. of Diamond:</p>
                                                            <p className='value2'>{productGems.diamondNo}</p>
                                                        </div>
                                                    </div>
                                                    <div className='weightd cat-data'>
                                                        <p className='heading'>Weight:</p>
                                                        <p className='value'>{data.diamond_wt} cent</p>
                                                    </div></> : ""}
                                            {this.state.data.ots === "1" ?
                                                <>
                                                    <div className='gold-data'>
                                                        <div className='metal cat-data'>
                                                            <p className='heading'>Stone:</p>
                                                            <p className='value'>{data.product_stone_category}</p>
                                                        </div>
                                                        <div className='carat cat-data'>
                                                            <p className='heading3'>No. of Stone:</p>
                                                            <p className='value3'>{productGems.stoneNo}</p>
                                                        </div>
                                                    </div>

                                                    <div className='weight cat-data'>
                                                        <p className='heading'>Weight:</p>
                                                        <p className='value'>{data.stone_wt} cent</p>
                                                    </div>  </> : ""}
                                        </div>)}


                                <p className={`mt-3 mb-10 ${data.product_stock_status === "MTO" && "d-none"}`} style={{ fontWeight: "bold", fontSize: "16px", color: "#F7418F" }}>More Customization Available*</p>
                                <div className="customization" onClick={() => { this.setState({ customCheck: true }) }}>

                                    <input type='checkbox' id='custom' className='checkbox-label' onClick={this.checkedCustom} />
                                    <label for='custom' className={`${data.product_stock_status === "MTO" && "d-none"}`}>Customization</label>
                                    <div className={`${data.product_stock_status === "MTO" ? "block" : "custom-content"}`}>
                                        {/* <div className={`custom-content ${data.product_stock_status === "MTO" ? "d-block" : "d-none"}`}> */}

                                        <div className="color-sec mb-7">
                                            <label>Material</label>
                                            <div className="color-box">
                                                <label className="m-0">
                                                    <input type="radio" name="material" value="Gold" onClick={this.showGoldQuality} />
                                                    <span className="choose-material">Gold</span>
                                                </label>
                                                {/* <label className="m-0">
                                                    <input type="radio" name="material" value="Silver" onClick={this.showSilverQuality} />
                                                    <span className="choose-material">Silver</span>
                                                </label> */}

                                                <label className="m-0">
                                                    <input type="radio" name="material" value="Platinum" onClick={this.showPlatinumQuality} />
                                                    <span className="choose-material">Platinum</span>
                                                </label>
                                                {/* <label className="m-0">
                                            <input type="radio" name="material" />
                                            <span className="choose-material" onClick={this.showDiamondQuality}>Diamond</span>
                                        </label>
                                       
                                        <label className="m-0">
                                            <input type="radio" name="material" onClick={()=>this.showStonelist()}/>
                                            <span className="choose-material">Stone</span>
                                        </label> */}
                                            </div>
                                        </div>

                                        {this.state.gQuality &&
                                            <div className="color-sec mb-7">
                                                <label>Color</label>
                                                <div className="color-box">
                                                    {/* <label className="m-0">
                                            <input type="radio" name="color" />
                                            <span className="choose-color red" />
                                        </label>
                                        <label className="m-0">
                                            <input type="radio" name="color" />
                                            <span className="choose-color yellow" />
                                        </label>
                                        <label className="m-0">
                                            <input type="radio" name="color" />
                                            <span className="choose-color blue" />
                                        </label>
                                        <label className="m-0">
                                            <input type="radio" name="color" />
                                            <span className="choose-color green" />
                                        </label> */}
                                                    <label className="m-0">
                                                        <input type="radio" name="material" value="Yellow Gold" onClick={this.metalColor} />
                                                        <span className="yellow-gold choose-material">Yellow Gold</span>
                                                    </label>
                                                    <label className="m-0">
                                                        <input type="radio" name="material" value="Rose Gold" onClick={this.metalColor} />
                                                        <span className="rose-gold choose-material">Rose Gold</span>
                                                    </label>
                                                    <label className="m-0">
                                                        <input type="radio" name="material" value="White Gold" onClick={this.metalColor} />
                                                        <span className="white-gold choose-material">White Gold</span>
                                                    </label>
                                                </div>

                                                <div className="col-12 check input-group d-flex align-items-center input-group-two mb-2">
                                                    <div className="title d-inline-block">
                                                        <label class="check-label radio-label input-group-two mt-2" for="flexRadioDefault1">Gold Quality:</label>
                                                    </div>
                                                    <div class="checkout-term align-items-center ml-2">
                                                        <input class="radio" type="radio" name="flexRadioDefault" value="14" id="flexRadioDefault1" onClick={this.onRadioChange} />
                                                        <label class="check-label radio-label input-group-two" for="flexRadioDefault1">14kt</label>
                                                    </div>
                                                    <div class="checkout-term align-items-center ml-5">
                                                        <input class="radio" type="radio" name="flexRadioDefault" value="18" id="flexRadioDefault2" onClick={this.onRadioChange} />
                                                        <label class="check-label radio-label input-group-two" for="flexRadioDefault2">18kt</label>
                                                    </div>
                                                    <div class="checkout-term align-items-center ml-5">
                                                        <input class="radio" type="radio" name="flexRadioDefault" value="22" id="flexRadioDefault2" onClick={this.onRadioChange} />
                                                        <label class="check-label radio-label input-group-two" for="flexRadioDefault2">22kt</label>
                                                    </div>

                                                </div>
                                            </div>}

                                        <div className="color-sec mb-20">
                                            {this.state.data.diamond === "1" || this.state.data.ots === "1" ?
                                                <>
                                                    <label>Gems</label>
                                                    <div className="color-box">

                                                        <label className="m-0">
                                                            <input type="radio" name="gems" value="Diamond" onClick={this.showDiamondQuality} />
                                                            <span className="choose-material">Diamond</span>
                                                        </label>


                                                        <label className="m-0">
                                                            <input type="radio" name="gems" value="Stone" onClick={this.showStonelist} />
                                                            <span className="choose-material">Stone</span>
                                                        </label>
                                                    </div></> : ""}


                                            {this.state.isStoneChecked ? (
                                                <div>
                                                    <select onChange={this.handleproductStone} className='stone-list'>
                                                        <option selected>Choose Stone</option>
                                                        {/* {this.state.stoneList.map((items,index)=>(
                                             <option key={index}>{items}</option>  
                                        ))} */}
                                                        {this.state.productStone.map((item, index) => (
                                                            <option key={item.mst_id} value={item.mst_id}>{item.mst_code}</option>
                                                        ))}
                                                    </select>

                                                </div>) : ''}

                                            {this.state.dQuality &&

                                                <div className="col-12 check input-group d-flex align-items-center input-group-two mb-20">
                                                    <div className="title d-inline-block">
                                                        <label class="check-label radio-label input-group-two mt-2" for="flexRadioDefault1">Diamond Quality:</label>
                                                    </div>
                                                    <div class="checkout-term align-items-center ml-2">
                                                        <input class="radio" type="radio" name="flexRadioDiamondDefault" id="flexRadioDefault1" value='SI-IJ' onClick={this.onDiamondChange} />
                                                        <label class="check-label radio-label input-group-two" for="flexRadioDefault1">SI-IJ</label>
                                                    </div>
                                                    <div class="checkout-term align-items-center ml-5">
                                                        <input class="radio" type="radio" name="flexRadioDiamondDefault" id="flexRadioDefault2" value='SI-GH' onClick={this.onDiamondChange} />
                                                        <label class="check-label radio-label input-group-two" for="flexRadioDefault2">SI-GH</label>
                                                    </div>
                                                    <div class="checkout-term align-items-center ml-5">
                                                        <input class="radio" type="radio" name="flexRadioDiamondDefault" id="flexRadioDefault2" value='VVS-GH' onClick={this.onDiamondChange} />
                                                        <label class="check-label radio-label input-group-two" for="flexRadioDefault2">VVS-GH</label>
                                                    </div>
                                                    <div class="checkout-term align-items-center ml-5">
                                                        <input class="radio" type="radio" name="flexRadioDiamondDefault" id="flexRadioDefault2" value='VVS-EF' onClick={this.onDiamondChange} />
                                                        <label class="check-label radio-label input-group-two" for="flexRadioDefault2">VVS-EF</label>
                                                    </div>
                                                </div>
                                            }
                                            {this.state.gemsCheck===false ? <div>
                                            <label style={{fontSize:'14px',color:"red"}}>Choose either Diamond or Stone</label>
                                            </div> :''}
                                        </div>
                                    </div>

                                </div>

                                {/* <div className="color-sec mb-20">
                                    <label>Material</label>
                                    <div className="color-box">
                                        <label className="m-0">
                                            <input type="radio" name="material" onClick={this.showGoldQuality} />
                                            <span className="choose-material">Gold</span>
                                        </label>
                                        <label className="m-0">
                                            <input type="radio" name="material" onClick={()=>this.setState({gQuality:false})}/>
                                            <span className="choose-material">Silver</span>
                                        </label>

                                        <label className="m-0">
                                            <input type="radio" name="material" onClick={()=>this.setState({gQuality:false})}/>
                                            <span className="choose-material">Platinum</span>
                                        </label> */}
                                {/* <label className="m-0">
                                            <input type="radio" name="material" />
                                            <span className="choose-material" onClick={this.showDiamondQuality}>Diamond</span>
                                        </label>
                                       
                                        <label className="m-0">
                                            <input type="radio" name="material" onClick={()=>this.showStonelist()}/>
                                            <span className="choose-material">Stone</span>
                                        </label> */}
                                {/* </div>
                                </div> */}

                                {/* {this.state.gQuality &&
                                <div className="color-sec mb-20">
                                    <label>Color</label>
                                    <div className="color-box"> */}
                                {/* <label className="m-0">
                                            <input type="radio" name="color" />
                                            <span className="choose-color red" />
                                        </label>
                                        <label className="m-0">
                                            <input type="radio" name="color" />
                                            <span className="choose-color yellow" />
                                        </label>
                                        <label className="m-0">
                                            <input type="radio" name="color" />
                                            <span className="choose-color blue" />
                                        </label>
                                        <label className="m-0">
                                            <input type="radio" name="color" />
                                            <span className="choose-color green" />
                                        </label> */}
                                {/* <label className="m-0">
                                            <input type="radio" name="material" />
                                            <span className="yellow-gold choose-material">Yellow Gold</span>
                                        </label>
                                        <label className="m-0">
                                            <input type="radio" name="material" />
                                            <span className="rose-gold choose-material">Rose Gold</span>
                                        </label>
                                        <label className="m-0">
                                            <input type="radio" name="material" />
                                            <span className="white-gold choose-material">White Gold</span>
                                        </label>
                                    </div> */}

                                {/* <div className="col-12 check input-group d-flex align-items-center input-group-two mb-2">
                                    <div className="title d-inline-block">
                                        <label class="check-label radio-label input-group-two mt-2" for="flexRadioDefault1">Gold Quality:</label>
                                    </div>
                                    <div class="checkout-term align-items-center ml-2">
                                        <input class="radio" type="radio" name="flexRadioDefault" value="14" id="flexRadioDefault1" onClick={this.onRadioChange} />
                                        <label class="check-label radio-label input-group-two" for="flexRadioDefault1">14kt</label>
                                    </div>
                                    <div class="checkout-term align-items-center ml-5">
                                        <input class="radio" type="radio" name="flexRadioDefault" value="18" id="flexRadioDefault2" onClick={this.onRadioChange} />
                                        <label class="check-label radio-label input-group-two" for="flexRadioDefault2">18kt</label>
                                    </div>
                                    <div class="checkout-term align-items-center ml-5">
                                        <input class="radio" type="radio" name="flexRadioDefault" value="22" id="flexRadioDefault2" onClick={this.onRadioChange} />
                                        <label class="check-label radio-label input-group-two" for="flexRadioDefault2">22kt</label>
                                    </div>
                                    <div class="checkout-term align-items-center ml-5">
                                        <input class="radio" type="radio" name="flexRadioDefault" value="24" id="flexRadioDefault2" onClick={this.onRadioChange} />
                                        <label class="check-label radio-label input-group-two" for="flexRadioDefault2">24kt</label>
                                    </div>
                                </div>
                                </div> } */}

                                {/* <div className="color-sec mb-20">
                                    <label>Gems</label>
                                    <div className="color-box">
                                        <label className="m-0">
                                            <input type="radio" name="material" />
                                            <span className="choose-material" onClick={this.showDiamondQuality}>Diamond</span>
                                        </label>
                                       
                                        <label className="m-0">
                                            <input type="radio" name="material" onClick={()=>this.showStonelist()}/>
                                            <span className="choose-material">Stone</span>
                                        </label>
                                    </div>
                                    {this.state.isStoneChecked?(
                                        <div>
                                        <select className='stone-list'>
                                        <option selected>Choose Stone</option>
                                        {this.state.stoneList.map((items,index)=>(
                                             <option key={index}>{items}</option>  
                                        ))}
                                        </select>
                                        </div>):''}

                                        {this.state.dQuality &&
                                         
                                        <div className="col-12 check input-group d-flex align-items-center input-group-two mb-20">
                                        <div className="title d-inline-block">
                                            <label class="check-label radio-label input-group-two mt-2" for="flexRadioDefault1">Diamond Quality:</label>
                                        </div>
                                        <div class="checkout-term align-items-center ml-2">
                                            <input class="radio" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value='SI-IJ' onClick={this.onDiamondChange} />
                                            <label class="check-label radio-label input-group-two" for="flexRadioDefault1">SI-IJ</label>
                                        </div>
                                        <div class="checkout-term align-items-center ml-5">
                                            <input class="radio" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value='SI-GH' onClick={this.onDiamondChange} />
                                            <label class="check-label radio-label input-group-two" for="flexRadioDefault2">SI-GH</label>
                                        </div>
                                        <div class="checkout-term align-items-center ml-5">
                                            <input class="radio" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value='VVS-GH' onClick={this.onDiamondChange} />
                                            <label class="check-label radio-label input-group-two" for="flexRadioDefault2">VVS-GH</label>
                                        </div>
                                        <div class="checkout-term align-items-center ml-5">
                                            <input class="radio" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value='VVS-EF' onClick={this.onDiamondChange} />
                                            <label class="check-label radio-label input-group-two" for="flexRadioDefault2">VVS-EF</label>
                                        </div>
                                    </div>
                                }
                                </div> */}

                                <div className="check input-group d-flex align-items-center input-group-two mt-3 mb-5">
                                    <div className="title d-inline-block">
                                        <h6>Size:</h6>
                                    </div>
                                    <div class="checkout-term align-items-center ml-3">
                                        <select className='size-list'>
                                            <option selected>Select Size</option>
                                            <option >1</option>
                                            <option >2</option>
                                            <option >3</option>
                                            <option >4</option>
                                            <option >5</option>
                                            <option >6</option>
                                            <option >7</option>
                                            <option >8</option>
                                            <option >9</option>
                                            <option >10</option>
                                            <option >11</option>
                                            <option >12</option>
                                            <option >13</option>
                                            <option >14</option>
                                            <option >15</option>
                                            <option >16</option>
                                            <option >17</option>
                                            <option >18</option>
                                            <option >19</option>
                                            <option >20</option>
                                            <option >21</option>
                                            <option >22</option>
                                            <option >23</option>
                                            <option >24</option>
                                            <option >25</option>
                                            <option >26</option>
                                            <option >27</option>
                                            <option >28</option>
                                            <option >29</option>
                                            <option >30</option>
                                        </select>
                                    </div>
                                    <a href='#' className='ml-3' onClick={this.showModal}>Not sure about size?</a>
                                </div>

                                {this.state.modal &&
                                    <Modal show={this.state.show} className="size-modal" onHide={this.handleClose} centered>
                                        <div className="modal-content" style={{ backgroundImage: "url(" + popupimg + ")" }}>
                                            <Modal.Header>
                                                <button type="button" className="close" onClick={this.handleClose}><span aria-hidden="true">×</span></button>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className="modal-inner">
                                                    <h3 className="title">Get Free Sizer</h3>
                                                    <form className='row'>
                                                        <div className='col-6'>
                                                            <input type="text" placeholder="Full Name" name="email" />
                                                        </div>
                                                        <div className='col-6'>
                                                            <input type="email" placeholder="Email Address" name="email" />
                                                        </div>
                                                        <div className='col-12'>
                                                            <input type="email" placeholder="Mobile Number" name="email" />
                                                        </div>
                                                        <div className='col-6'>
                                                            <input type="text" placeholder="Apartment / Flat No." name="email" />
                                                        </div>
                                                        <div className='col-6'>
                                                            <input type="text" placeholder="Street Name" name="email" />
                                                        </div>
                                                        <div className='col-6'>
                                                            <input type="text" placeholder="Area / Locality" name="email" />
                                                        </div>
                                                        <div className='col-6'>
                                                            <input type="text" placeholder="Land Mark" name="email" />
                                                        </div>
                                                        <div className='col-4'>
                                                            <input type="text" placeholder="Pin Code" name="email" />
                                                        </div>
                                                        <div className='col-4'>
                                                            <input type="text" placeholder="City" name="email" />
                                                        </div>
                                                        <div className='col-4'>
                                                            <input type="text" placeholder="State" name="email" />
                                                        </div>
                                                        <div className="col-12 d-flex justify-content-center">
                                                            <button type="submit" className="size-btn" name="button">Submit</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </Modal.Body>
                                        </div>
                                    </Modal>
                                }

                                <div className="quantity-cart d-block d-sm-flex">
                                    <div className="quantity-box">
                                        <button type="button" className="minus-btn" onClick={this.DecreaseItem}><i className="fal fa-minus" /></button>
                                        <input type="text" className="input-qty" name="name" value={clicks} onChange={this.handleChange} readOnly />
                                        <button type="button" className="plus-btn" onClick={this.IncrementItem}><i className="fal fa-plus" /></button>
                                    </div>
                                    <div className="cart-btn pl-40">
                                        <button onClick={this.handleAddToCart} className="main-btn btn-filled">Place your Order</button>
                                        <p className='discl mt-3'>Product will be delivered in 15 days</p>
                                    </div>
                                </div>

                                <div className="mb-20 mt-5">
                                    <div className="d-flex">
                                        <label className="m-0 d-flex align-items-center">
                                            <span className=""><img src={returnicon} alt='icon' /></span>
                                            <p className="discl ml-3">30 day returnable</p>
                                        </label>
                                        <label className="m-0 d-flex align-items-center ml-3">
                                            <span className=""><img src={buyback} alt='icon' /></span>
                                            <p className="short-descr ml-3">Life time exchange & buyback</p>
                                        </label>
                                        <label className="m-0 d-flex align-items-center ml-3">
                                            <span className=""><img src={Certified} alt='icon' /></span>
                                            <p className="ml-3">Certified Jewellery</p>
                                        </label>
                                    </div>
                                </div>

                                {/* <div className="other-info flex mt-20">
                                    <h6>Tags:</h6>
                                    <ul>{tagListItems}</ul>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="product-description mt-100">
                                <Tab.Container defaultActiveKey="description">
                                    <div className="tabs">
                                        <Nav variant="tabs" className=" justify-content-center">
                                            {/* <Nav.Item>
                                                <Nav.Link eventKey="description">Description</Nav.Link>
                                            </Nav.Item> */}
                                            {/* <Nav.Item>
                                                <Nav.Link eventKey="review">Reviews (3)</Nav.Link>
                                            </Nav.Item> */}
                                            {/* <Nav.Item>
                                                <Nav.Link eventKey="details">Details</Nav.Link>
                                            </Nav.Item> */}
                                            {/* <Nav.Item>
                                                <Nav.Link eventKey="addinfo">Additional Info</Nav.Link>
                                            </Nav.Item> */}
                                        </Nav>
                                        <Tab.Content>
                                            {/* <Tab.Pane eventKey="description">
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fringilla augue nec est tristique auctor. Ipsum metus feugiat sem, quis fermentum turpis eros eget velit. Donec ac tempus ante. Fusce ultricies massa massa. Fusce aliquam, purus eget sagittis vulputate, sapien libero hendrerit est, sed commodo augue nisi non neque.Cras neque metus, consequat et blandit et, luctus a nunc. Etiam gravida vehicula tellus, in imperdiet ligula euismod eget. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nam erat mi, rutrum at sollicitudin rhoncusp</p>
                                            </Tab.Pane> */}
                                            <Tab.Pane eventKey="review">
                                                {/* Your review component goes here */}
                                            </Tab.Pane>
                                            {/* <Tab.Pane eventKey="details" className="additional-info"> */}
                                            <div className="additional-info">
                                                <div>
                                                    <h4 className="text-dark mb-10">Product Details</h4>
                                                    <table className="table detail-table">
                                                        <tbody>
                                                            <tr>
                                                                <td><b>Product Code</b></td>
                                                                <td className="value">{data.product_code}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><b>Height</b></td>
                                                                <td className="value">{data.product_height}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><b>Width</b></td>
                                                                <td className="value">{data.product_width}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><b>Product Weight</b></td>
                                                                <td className="value">{productWeight.product_weight}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {this.state.data.diamond === "1" ?
                                                    <div className='mt-5'>
                                                        <h4 className="text-dark mb-10">Diamond Details</h4>
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td><b>Total Weight</b></td>
                                                                    <td className="value">{productWeight.diamond_weight}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>Total No. of Diamonds</b></td>
                                                                    <td className="value">{productGems.diamondNo}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>Price</b></td>
                                                                    <td className="value">{this.state.isCustom ? productPrice.diamond : this.state.tablePrice.milli_diamond_price}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div> : " "}
                                                {this.state.data.ots === "1" ?
                                                    <div className='mt-5'>
                                                        <h4 className="text-dark mb-10">Stone Details</h4>
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td><b>Total Weight</b></td>
                                                                    <td className="value">{productWeight.stone_weight}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>Total No. of Stone</b></td>
                                                                    <td className="value">{productGems.stoneNo}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>Price</b></td>
                                                                    <td className="value">{this.state.isCustom ? productPrice.stone : this.state.tablePrice.milli_stone_price}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div> : " "}
                                                <div className='mt-5'>
                                                    <h4 className="text-dark mb-10">Metal Details</h4>
                                                    <table className="table">
                                                        <tbody>
                                                            <tr>
                                                                <td><b>Type</b></td>
                                                                <td className="value">{data.product_type}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><b>Weight</b></td>
                                                                <td className="value">{productWeight.metal_weight}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><b>Price</b></td>
                                                                <td className="value">{this.state.isCustom ? productPrice.metal : this.state.tablePrice.product_price}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className='mt-5'>
                                                    <h4 className="text-dark mb-10">Price breakup</h4>

                                                    <table className="table">
                                                        <tbody>
                                                            <tr>
                                                                <td><b>{data.product_type}</b></td>
                                                                <td className="value">{this.state.isCustom ? productPrice.metal : this.state.tablePrice.product_price}</td>
                                                            </tr>
                                                            {this.state.data.diamond === "1" ?
                                                                <tr>
                                                                    <td><b>Diamond</b></td>
                                                                    <td className="value">{this.state.isCustom ? productPrice.diamond : this.state.tablePrice.milli_diamond_price}</td>
                                                                </tr> : ""}

                                                            {this.state.data.ots === "1" ?
                                                                <tr>
                                                                    <td><b>Stone</b></td>
                                                                    <td className="value">{this.state.isCustom ? productPrice.stone : this.state.tablePrice.milli_stone_price}</td>
                                                                </tr> : ""}
                                                            <tr>
                                                                <td><b>30% off on making Charges</b></td>
                                                                <td className="value">{productPrice.mk_charge}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><b>GST</b></td>
                                                                <td className="value">{productPrice.gst}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><b>Total</b></td>
                                                                <td className="value">{this.state.isCustom ? (productPrice.total) : (this.state.productValue)}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className='mt-5'>
                                                    <h4 className="text-white mb-20 mt-2">Product Details</h4>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fringilla augue nec est tristique auctor. Ipsum metus feugiat sem, quis fermentum turpis eros eget velit. Donec ac tempus ante. Fusce ultricies massa massa. Fusce aliquam, purus eget sagittis vulputate, sapien libero hendrerit est, sed commodo augue nisi non neque.Cras neque metus, consequat et blandit et, luctus a nunc. Etiam gravida vehicula tellus, in imperdiet ligula euismod eget. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nam erat mi, rutrum at sollicitudin rhoncusp</p>
                                                </div>
                                                <div className="other-info d-flex align-items-center mt-20">
                                                    <h6>Tags:</h6>
                                                    <p className="value">{tagListItems}</p>
                                                </div>
                                            </div>
                                            {/* </Tab.Pane> */}
                                            {/* <Tab.Pane eventKey="addinfo" className="additional-info">
                                                <div>
                                                    <h3 className="text-white mb-20">Additional Information</h3>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Attributes</th>
                                                                <th>Values</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><b>Color</b></td>
                                                                <td className="value">Red, White, Black</td>
                                                            </tr>
                                                            <tr>
                                                                <td><b>Material</b></td>
                                                                <td className="value">Steel, Wood, Stone</td>
                                                            </tr>
                                                            <tr>
                                                                <td><b>Weight</b></td>
                                                                <td className="value">400 Gram</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Tab.Pane> */}
                                        </Tab.Content>
                                    </div>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}


const mapStateToProps = state => {
    return {
        ...state,
        data: state.customers
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addCartList: (data) => dispatch(addCartList(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Shopinfo));

// export default withRouter(Shopinfo);
