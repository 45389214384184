import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Backtotop from './Backtotop';

// import logo from '../../assets/img/milli-footer-logo.png'
import millinewLogo from '../../assets/img/mili_logo_new_x5.png';
import Milli from '../../assets/img/milli-i.svg'

class Footertwo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryItems: []
        };
    }

    async getCategories() {
        //Display a category items 
        const api = await fetch(process.env.REACT_APP_API_BASE_URL + '/api/category')
        const data = await api.json()

        this.setState({ categoryItems: data })
    }

    componentDidMount() {
        this.getCategories()
    }

    render() {
        return (
            <Fragment>
                <Backtotop />
                <footer className="footer-two">
                    <div className="footer-widget-area pt-100 pb-50">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-sm-6 order-1">
                                    {/* Site Info Widget */}
                                    <div className="widget site-info-widget mb-50">
                                        <div className="footer-logo mb-50">
                                            <img src={millinewLogo} alt="Logo" />
                                        </div>
                                        <p>
                                            Vision,<br></br>
                                            To be a globally acclaimed leading jewellery lifestyle brand.
                                            Mission
                                            A brand for global  jewellery designs accessable to all the buyers at affordable prices.                                        </p>
                                        {/* <div className="social-links mt-40">
                                            <Link to="#"><i className="fab fa-facebook-f" /></Link>
                                            <Link to="#"><i className="fab fa-twitter" /></Link>
                                            <Link to="#"><i className="fab fa-behance" /></Link>
                                            <Link to="#"><i className="fab fa-linkedin" /></Link>
                                            <Link to="#"><i className="fab fa-youtube" /></Link>
                                        </div> */}
                                        <div className="join-us mt-5">
                                            <Link to="/new-manufacturer">
                                                <button type="submit" className="join">Join as Manufacturer or Reseller</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 order-3 order-lg-2">
                                    {/* Nav Widget */}
                                    <div className="widget nav-widget mb-50">
                                        <div>
                                            <h4 className="widget-title">New Collections.</h4>
                                            <ul>
                                                {this.state.categoryItems.map((item, element) => (
                                                    <li><Link to={`/alljewellery/productfilter/${JSON.stringify({'category': item.pr_cat_name })}`}><img src={Milli} alt='icon' />{item.pr_cat_name}</Link></li>
                                                ))}
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 order-2 order-lg-3">
                                    {/* Contact Widget */}
                                    <div className="widget contact-widget mb-50">
                                        <h4 className="widget-title">Contact Us.</h4>
                                        <div className="contact-lists">
                                            <div className="contact-box">
                                                <div className="icon">
                                                    <i className="flaticon-phone" />
                                                </div>
                                                <div className="desc">
                                                    <h6 className="title">Phone Number</h6>
                                                    +91 90366 37777
                                                </div>
                                            </div>
                                            <div className="contact-box">
                                                <div className="icon">
                                                    <i className="flaticon-message" />
                                                </div>
                                                <div className="desc">
                                                    <h6 className="title">Email Address</h6>
                                                    <Link to="#">cs@millijewel.com</Link>
                                                    <Link to="#">info@millijewel.com</Link>
                                                </div>
                                            </div>
                                            <div className="contact-box">
                                                <div className="icon">
                                                    <i className="flaticon-location-pin" />
                                                </div>
                                                <div className="desc">
                                                    <h6 className="title">Office Address</h6>
                                                    {/* Regst office- Suite no 8 3rd floor, 1537, 5th Main Rd, Rajiv Gandhi Nagar, HSR
                                                    Layout, Bengaluru, Karnataka 560102<br></br><br></br> */}
                                                    RMZ, Millenia Business Park, Campus 1A, NO.143, Dr.M.G.R. Road, (North Veeranam Salai), Perungudi,Sholinganallur, Chennai-600096.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright-area pt-30 pb-30">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-5 order-2 order-md-1">
                                    <p className="copyright-text copyright-two">Rights reserved by <Link to="#">Milli</Link> - 2024</p>
                                </div>
                                <div className="col-lg-6 col-md-7 order-1 order-md-2">
                                    <div className="footer-menu text-center text-md-right">
                                        <ul>
                                            <li><Link to="#">Terms of use</Link></li>
                                            <li><Link to="/legal">Privacy Policy</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>

            </Fragment>
        );
    }
}

export default Footertwo;