import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';



import placed from '../../../assets/img/order-track/plc.png';
import picked from '../../../assets/img/order-track/pkd.png';
import ofd from '../../../assets/img/order-track/ofd.png';
import delivered from '../../../assets/img/order-track/del.png';

import { addCartList } from "../../../slices/customerSlice";
import { connect } from 'react-redux';

// import img1 from '../../../assets/img/shop/cart-1.png';
// import img2 from '../../../assets/img/shop/cart-2.png';
// import img3 from '../../../assets/img/shop/cart-3.png';

// const orderlists = [
//     { head: 'Product1', desc: 'Delivered' },
//     { head: 'Product1', desc: 'Delivered' },
// ];


class Orderdetails extends Component {



    render() {

        return (
            <section className="Shop-section pt-120 pb-120">
                <div className='container'>
                    <div className='col-12'>
                        <div className='col-12'>
                            <div className="box-header">
                                <div className="">
                                    <h5>Order Tracking</h5>
                                </div>
                                <div className="order-id">#SPK1218153635</div>
                            </div>
                        </div>

                        <div className='track-dtl mt-5'>
                            <div className='d-flex justify-content-center'>
                                <div className='order-placed'>
                                    <div className='plc'>
                                        <div ClassName="plc-img">
                                            <span ClassName="" id='png'>
                                                <img src={placed} alt="" />
                                            </span>
                                        </div>
                                        <div ClassName="plc-txt">
                                            <p ClassName="">Order Placed</p>
                                            <span ClassName="">Nov 03, 2022</span>
                                        </div>
                                    </div>
                                    <div className='plc-dsc'>
                                        <div ClassName="plc-txt">
                                            <p ClassName="">Order placed successfully by <a href="#"
                                                ClassName="font-weight-semibold text-primary">Sansa Taylor</a></p>
                                            <span ClassName="">Nov 03, 2022</span>
                                        </div>
                                    </div>
                                </div>

                                <div className='order-picked'>
                                    <div className='pkd'>
                                        <div ClassName="plc-img">
                                            <span ClassName="" id='png'>
                                                <img src={picked} alt="" />
                                            </span>
                                        </div>
                                        <div ClassName="pkd-txt">
                                            <p ClassName="">Order Picked</p>
                                            <span ClassName="">Nov 03, 2022</span>
                                        </div>
                                    </div>
                                    <div className='pkd-dsc'>
                                        <div ClassName="pkd-txt">
                                            <p ClassName="">Order placed successfully by <a href="#"
                                                ClassName="font-weight-semibold text-primary">Sansa Taylor</a></p>
                                            <span ClassName="">Nov 03, 2022</span>
                                        </div>
                                    </div>
                                </div>

                                <div className='order-ofd'>
                                    <div className='ofd'>
                                        <div ClassName="ofd-img mr-5">
                                            <span ClassName="" id='png'>
                                                <img src={ofd} alt="" />
                                            </span>
                                        </div>
                                        <div ClassName="ofd-txt">
                                            <p ClassName="">Out for Delivery</p>
                                            <span ClassName="">Nov 03, 2022</span>
                                        </div>
                                    </div>
                                    <div className='ofd-dsc'>
                                        <div ClassName="ofd-txt">
                                            <p ClassName="">Order placed successfully by <a href="#"
                                                ClassName="font-weight-semibold text-primary">Sansa Taylor</a></p>
                                            <span ClassName="">Nov 03, 2022</span>
                                        </div>
                                    </div>
                                </div>

                                <div className='order-del'>
                                    <div className='del'>
                                        <div ClassName="del-img">
                                            <span ClassName="" id='png'>
                                                <img src={delivered} alt="" />
                                            </span>
                                        </div>
                                        <div ClassName="del-txt">
                                            <p ClassName="">Delivered</p>
                                            <span ClassName="">Nov 03, 2022</span>
                                        </div>
                                    </div>
                                    <div className='del-dsc'>
                                        <div ClassName="plc-txt">
                                            <p ClassName="">Order placed successfully by <a href="#"
                                                ClassName="font-weight-semibold text-primary">Sansa Taylor</a></p>
                                            <span ClassName="">Nov 03, 2022</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="detail-table">
                        <table className="table cw-cart-table mt-5">
                            <tbody>
                                <tr>
                                    <td className="product-price text-dark cw-align has-title" data-title="Order ID"><h6>Product Name</h6></td>
                                    <td className="product-price text-dark cw-align has-title" data-title="Order Date">Chain with Pendant</td>
                                </tr>
                                <tr>
                                    <td className="product-price text-dark cw-align has-title" data-title="Order ID"><h6>Quantity</h6></td>
                                    <td className="product-price text-dark cw-align has-title" data-title="Order Date">1</td>
                                </tr>
                                <tr>
                                    <td className="product-price text-dark cw-align has-title" data-title="Order ID"><h6>Shipping Address</h6></td>
                                    <td className="product-price text-dark cw-align has-title" data-title="Order Date">No.7, 1st Street, Main Road, Chennai-600 001</td>
                                </tr>
                                <tr>
                                    <td className="product-price text-dark cw-align has-title" data-title="Order ID"><h6>Billing Address</h6></td>
                                    <td className="product-price text-dark cw-align has-title" data-title="Order Date">No.7, 1st Street, Main Road, Chennai-600 001</td>
                                </tr>
                                <tr>
                                    <td className="product-price text-dark cw-align has-title" data-title="Order ID"><h6>Invoice Number</h6></td>
                                    <td className="product-price text-dark cw-align has-title" data-title="Order Date">#SPK1218153635</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <>
                    {/* <div className="col-12">
                    <div className='col-12'>
                        <div className="box-header">
                            <div className="box-title">
                                Order Tracking
                            </div>
                            <div className="ms-auto text-success">#SPK1218153635</div>
                        </div>
                    </div>
                    <div class="order-track">
                        <div className="accordion-group">
                            <div ClassName="hs-accordion">
                                <div ClassName="order-plc">
                                    <div ClassName="plc-img me-2">
                                        <span ClassName="avatar avatar-md avatar-rounded">
                                            <img src={placed} alt="" />
                                        </span>
                                    </div>
                                    <div ClassName="flex-grow">
                                        <p ClassName="">Order Placed</p>
                                        <span ClassName="">Nov 03, 2022</span>
                                    </div>
                                </div>
                                <div id="order-collapse-one"
                                    ClassName="hs-accordion-content w-full overflow-hidden transition-[height] duration-300"
                                    aria-labelledby="order-heading-one">
                                    <div ClassName="accordion-body">
                                        <div ClassName="text-[0.6875rem]">
                                            <p ClassName="mb-0">Order placed successfully by <a href="javascript:void(0);"
                                                ClassName="font-weight-semibold text-primary">Sansa Taylor</a>
                                            </p>
                                            <span ClassName="text-[#8c9097] dark:text-white/50 opacity-[0.5]">Nov
                                                03, 2022, 15:36</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <div ClassName="hs-accordion">
                                    <div ClassName="order-plc">
                                        <div ClassName="plc-img me-2">
                                            <span ClassName="avatar avatar-md avatar-rounded">
                                                <img src={placed} alt="" />
                                            </span>
                                        </div>
                                        <div ClassName="flex-grow">
                                            <p ClassName="">Order Placed</p>
                                            <span ClassName="">Nov 03, 2022</span>
                                        </div>
                                    </div>
                                    <div id="order-collapse-one"
                                        ClassName="hs-accordion-content w-full overflow-hidden transition-[height] duration-300"
                                        aria-labelledby="order-heading-one">
                                        <div ClassName="accordion-body">
                                            <div ClassName="text-[0.6875rem]">
                                                <p ClassName="mb-0">Order placed successfully by <a href="javascript:void(0);"
                                                    ClassName="font-weight-semibold text-primary">Sansa Taylor</a>
                                                </p>
                                                <span ClassName="text-[#8c9097] dark:text-white/50 opacity-[0.5]">Nov
                                                    03, 2022, 15:36</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div ClassName="col-3 hs-accordion active" id="order-heading-three">
                                    <a href="javascript:void(0);"
                                        ClassName="hs-accordion-toggle group pb-3 inline-flex items-center gap-x-3 w-full font-semibold text-start transition"
                                        aria-controls="order-collapse-three">
                                        <div ClassName="flex mb-0">
                                            <div ClassName="ord-pkd me-2">
                                                <span ClassName="avatar avatar-md avatar-rounded">
                                                    <img src={placed} alt="" />
                                                </span>
                                            </div>
                                            <div ClassName="flex-grow">
                                                <p ClassName="font-semibold mb-0 text-[0.875rem]">Picked</p>
                                                <span ClassName="text-[0.75rem]">Nov 03, 15:10</span>
                                            </div>
                                        </div>
                                    </a>
                                    <div id="order-collapse-two"
                                        ClassName="hs-accordion-content w-full overflow-hidden transition-[height] duration-300"
                                        aria-labelledby="order-heading-two">
                                        <div ClassName="accordion-body">
                                            <div ClassName="text-[0.6875rem]">
                                                <p ClassName="mb-0">Your order has been picked up by
                                                    <span ClassName="font-weight-semibold">Smart Good Services</span></p>
                                                <span ClassName="text-[#8c9097] dark:text-white/50 opacity-[0.5]">Nov 03, 2022, 15:36</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div ClassName="col-3 hs-accordion active" id="order-heading-three">
                                    <a href="javascript:void(0);"
                                        ClassName="hs-accordion-toggle group pb-3 inline-flex items-center gap-x-3 w-full font-semibold text-start transition"
                                        aria-controls="order-collapse-three">
                                        <div ClassName="flex mb-0">
                                            <div ClassName="ord-shp me-2">
                                                <span ClassName="avatar avatar-md avatar-rounded">
                                                    <img src={placed} alt="" />
                                                </span>
                                            </div>
                                            <div ClassName="flex-grow">
                                                <p ClassName="font-semibold mb-0 text-[0.875rem]">Shipping</p>
                                                <span ClassName="text-[0.75rem]">Nov 03, 15:10</span>
                                            </div>
                                        </div>
                                    </a>
                                    <div id="order-collapse-three"
                                        ClassName="hs-accordion-content w-full overflow-hidden transition-[height] duration-300"
                                        aria-labelledby="order-heading-three">
                                        <div ClassName="accordion-body">
                                            <div ClassName="text-[0.6875rem] mb-4">
                                                <p ClassName="mb-0">Arrived USA <span ClassName="font-weight-semibold">SGS
                                                    Warehouse</span></p>
                                                <span ClassName="text-[#8c9097] dark:text-white/50 opacity-[0.5]">Nov 03, 2022,
                                                    15:36</span>
                                            </div>
                                            <div ClassName="text-[0.6875rem] mb-4">
                                                <p ClassName="mb-0">picked up by <span ClassName="font-weight-semibold">SGS
                                                    Agent</span>
                                                    and on the way to Hyderabad</p>
                                                <span ClassName="text-[#8c9097] dark:text-white/50 opacity-[0.5]">Nov 03, 2022,
                                                    15:36</span>
                                            </div>
                                            <div ClassName="text-[0.6875rem]">
                                                <p ClassName="mb-0">Arrived in Hyderabad and expected Delivery is <span
                                                    ClassName="font-weight-semibold">Apr 16, 2022</span> </p>
                                                <span ClassName="text-[#8c9097] dark:text-white/50 opacity-[0.5]">Nov 03, 2022,
                                                    15:36</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div ClassName="col-2 hs-accordion" id="order-heading-four">
                                    <a href="javascript:void(0);"
                                        ClassName="hs-accordion-toggle group pb-3 inline-flex items-center gap-x-3 w-full font-semibold text-start transition">
                                        <div ClassName="flex mb-0">
                                            <div ClassName="out-del me-2">
                                                <span ClassName="avatar avatar-md avatar-rounded">
                                                    <img src={placed} alt="" />
                                                </span>
                                            </div>
                                            <div ClassName="flex-grow">
                                                <p ClassName="font-semibold mb-0 text-[0.875rem]">Out For Delivery</p>
                                                <span ClassName="text-[#8c9097] dark:text-white/50 text-[0.75rem]">Nov 03, 15:10
                                                    (expected)</span>
                                            </div>
                                        </div>
                                    </a>
                                    <div id="order-collapse-four"
                                        ClassName="hs-accordion-content hidden  w-full overflow-hidden transition-[height] duration-300"
                                        aria-labelledby="order-heading-four">
                                        <div ClassName="accordion-body">
                                            <div ClassName="text-[0.6875rem]">
                                                <p ClassName="mb-0">Your order is out for devlivery</p>
                                                <span ClassName="text-[#8c9097] dark:text-white/50 opacity-[0.5] ps-4">Nov 03,
                                                    2022,
                                                    15:36 (expected)</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div ClassName="col-2 hs-accordion" id="order-heading-four">
                                    <a href="javascript:void(0);"
                                        ClassName="hs-accordion-toggle group pb-3 inline-flex items-center gap-x-3 w-full font-semibold text-start transition">
                                        <div ClassName="flex mb-0">
                                            <div ClassName="del me-2">
                                                <span ClassName="">
                                                    <img src={placed} alt="" />
                                                </span>
                                                <span><i ClassName="fa fa-package"></i></span>
                                            </div>
                                            <div ClassName="flex-grow">
                                                <p ClassName="">Delivered</p>
                                                <span ClassName="">Nov 03, 18:42</span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                </>
                <div className="container">
                    <div className="row justify-content-center">
                        {/* <div className="col-12">
                            <div className='shop-pin d-flex justify-content-center align-items-center mb-5'>
                                <div className=''>
                                    <p className="page-title">Your Pincode</p>
                                </div>
                                <div className="widget search-widget  ml-5">
                                    <form className='d-flex align-items-center' action="#">
                                        <input className='pin-input' type="text" placeholder="Pincode" />
                                        <button type="submit">Update</button>
                                    </form>
                                </div>
                                <div className=' ml-5'>
                                    <p>Provide pincode for delivery date & nearby stores!</p>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="col-lg-5 mt-5">
                            <div className="shop-detail-image">
                                <Slider className="detail-slider-1" {...settings} asNavFor={this.state.nav2} ref={slider => (this.slider1 = slider)}>
                                    <div className="slide-item">
                                        <div className="image-box">
                                            <Link to="#">
                                                <img src={data.product_parent_image} className="img-fluid" alt="img" />
                                                <p className='discl mt-3'>Actual product may vary from the image show</p>
                                            </Link>
                                        </div>
                                    </div>
                                </Slider>
                                <span className="rating mb-20">
                                    <span className="tag">Certified by:</span>
                                    <span className="pro-review ml-3"><img src={bis} /></span>
                                    <span className="pro-review ml-3"><img src={igi} /></span>
                                    <span className="pro-review ml-3"><img src={gold} /></span>
                                    <span className="pro-review ml-3"><img src={silver} /></span>
                                    <span className="pro-review ml-3"><img src={platinum} /></span>
                                </span>
                            </div>
                        </div> */}

                    </div>
                </div>
            </section>
        );
    }
}


const mapStateToProps = state => {
    return {
        ...state,
        data: state.customers
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addCartList: (data) => dispatch(addCartList(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Orderdetails));

// export default withRouter(Shopinfo);
