import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

// import img1 from '../../../assets/img/shop/001.jpg';
// import img2 from '../../../assets/img/shop/002.jpg';
// import img3 from '../../../assets/img/shop/003.jpg';
// import img4 from '../../../assets/img/shop/004.jpg';
// import img5 from '../../../assets/img/shop/005.jpg';

class Content extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/api/cart/find-by-user-id/1');
            const data = await response.json();

            if (data.message == undefined) {
                this.setState({ data });
            } else {
                this.setState({ data: [] });
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    render() {
        const { data } = this.state;

        return (
            <section className="cart-section wishlist pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="w-100 table-responsive mb-60">
                                <table className="table cw-cart-table mb-0">
                                    <thead>
                                        <tr>
                                            <th />
                                            <th scope="col" className="product-name">Product</th>
                                            <th scope="col" className="product-qty">Price</th>
                                            <th scope="col" className="product-price">Availability</th>
                                            <th scope="col" className="product-price">Total</th>
                                            <th scope="col" className="product-price">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, i) => (
                                            <tr key={i}>
                                                <td className="product-remove text-center cw-align">
                                                    <Link to="#"><i className="fas fa-times" /></Link>
                                                </td>
                                                <td data-title="Product" className="has-title">
                                                    <div className="product-thumbnail">
                                                        <img src={item.product_parent_image} alt="" />
                                                    </div>
                                                    <Link to="/shop-detail">{item.product_name}</Link>
                                                </td>
                                                <td className="product-price text-white cw-align has-title" data-title="Price">
                                                    <span className="product-currency"><b>Rs.</b></span> <span className="product-amount"><b>{item.product_price}</b></span>
                                                </td>
                                                <td data-title="Availability" className="has-title">
                                                    <span className="text-success fw-600">{item.product_stock_status}</span>
                                                    <span className="text-danger fw-600">{item.product_stock_status}</span>
                                                </td>
                                                <td className="product-price text-white cw-align has-title" data-title="Total">
                                                    <span className="product-currency"><b>Rs.</b></span> <span className="product-amount"><b>{item.product_price}</b></span>
                                                </td>
                                                <td data-title="Actions" className="has-title">
                                                    <Link to="#" className="main-btn btn-filled">Add to Cart</Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="share-wishlist text-center">
                                <h4>Share Your Wishlist</h4>
                                <div className="social-media">
                                    <Link to="#"><i className="fab fa-facebook-f" /></Link>
                                    <Link to="#"><i className="fab fa-twitter" /></Link>
                                    <Link to="#"><i className="fab fa-behance" /></Link>
                                    <Link to="#"><i className="fab fa-linkedin" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}

export default Content;