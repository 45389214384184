import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import $ from 'jquery';
// import milliLogo from '../../assets/img/milli-logo.png';
import millinewLogo from '../../assets/img/mili_logo_new_x5.png';

// import daily1 from '../../assets/img/shop/studs-d.jpg';
// import daily2 from '../../assets/img/shop/chains-d.jpg';
// import daily3 from '../../assets/img/shop/ring-d.jpg';
// import daily4 from '../../assets/img/shop/anklets-d.jpg';

import gift1 from '../../assets/img/menu/gift1.jpg';
import gift2 from '../../assets/img/menu/gift2.jpg';
import gift3 from '../../assets/img/menu/gift3.jpg';
import gift4 from '../../assets/img/menu/gift4.jpg';

import custom from '../../assets/img/we-offer/custom3.jpg';

import Canvas from './MilliCanvas';
import Mobilemenu from './Mobilemenu';
// import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import Cookies from 'universal-cookie';

class Headertwo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classmethod: false,
            togglemethod: false,
            isLoggedIn: false,
            loggedOut: false,
            error: null, // Default login status
            userName: '', // Initialize userName
            showCartDtl: 0,
            cartCount: 0,
            // menuOption: [{ 'Gold': ['Rings', 'Earrings', 'Bracelets', 'Pendants', 'Necklaces', 'Daily Wears'] },
            // { 'Diamond': ['Rings', 'Earrings', 'Bracelets', 'Pendants', 'Necklaces', 'Daily Wears'] },
            // { 'Platinum': ['Rings', 'Earrings', 'Bracelets', 'Pendants', 'Necklaces', 'Daily Wears'] },
            // { 'Silver': ['Rings', 'Earrings', 'Bracelets', 'Pendants', 'Necklaces', 'Daily Wears'] }],
            categoryItems :[],
            tagItems:[],
            // menuOption:[{'Gold':[]},{'Diamond':[]},{'Platinum':[]},{'Silver':[]}]
            menuOption:[{'Gold':[]},{'Diamond':[]},{'Platinum':[]}]

        };
        this.addClass = this.addClass.bind(this);
        this.removeClass = this.removeClass.bind(this);
        this.toggleClass = this.toggleClass.bind(this);
    }

    addClass() {
        this.setState({
            classmethod: true,
            showCartDtl: this.state.showCartDtl + 1
        });
    }

    removeClass() {
        this.setState({
            classmethod: false
        });
    }

    toggleClass() {
        this.setState({
            togglemethod: !this.state.togglemethod
        });
    }

    handleLogout = async () => {
        try {

            const response = await fetch(process.env.REACT_APP_API_BASE_URL +'/api/auth/logout', {
                method: 'POST',
            });

            if (response.ok) {
                // Clear local storage
                localStorage.removeItem('userData');
                // Update state to indicate successful logout
                this.setState({ loggedOut: true });
                window.location.reload();
            } else {
                // Handle logout error
                const data = await response.json();
                this.setState({ error: data.message || 'Logout failed' });
            }
        } catch (error) {
            console.error('Error logging out:', error);
            this.setState({ error: 'An error occurred while logging out. Please try again later.' });
        }
    };

    componentWillReceiveProps() {
        this.updateCardCount();
    }
    megamenu() {
        
        $('.sigm-megamenu-nav>li').on('mouseover', function (e) {
           
            e.preventDefault();
            // Remove active class from all menu items
            $('.sigm-megamenu-nav>li a').removeClass('active');

            // Add active class to the hovered menu item
            $(this).find('a').addClass('active');

            // Find the corresponding submenu and tab item
            var submenu = $(this).closest('.sub-menu');
            var tabIndex = $(this).index();
            var tabItem = submenu.find('.tab-item').eq(tabIndex); 
    
            // Remove active class from all tab items within the submenu
            submenu.find('.tab-item').removeClass('active show');

            // Add active class to the tab item corresponding to the hovered menu item
            tabItem.addClass('active show');
        });
    }
    componentDidMount() {
        this.updateCardCount();
        this.displayCategories();
        window.addEventListener('scroll', () => {
            this.setState({
                isTop: window.scrollY > 110
            });
        }, false);

        // Check if the user is logged in
        const userData = localStorage.getItem('userData');
        if (userData) {
            // Parse the JSON string into an object
            const userDataObj = JSON.parse(userData);
            // Update state with user details
            this.setState({
                isLoggedIn: true,
                userName: userDataObj.userName
            });
        }
    }

    updateCardCount = () => {
        const isLoggedIn = JSON.parse(localStorage.getItem('userData'));
        if (isLoggedIn) {
            this.fetchData(isLoggedIn.userId);
        } else {
            this.getCookiesCartList();
        }
    }
   
    setDarkMode(){
        document.querySelector("body").setAttribute("toggle-theme","dark")
    }
    setLightMode(){
        document.querySelector("body").setAttribute("toggle-theme","light")
    }

    setToggleTheme = (e)=>{
        if(e.target.checked){
            this.setDarkMode();
        }
        else{
            this.setLightMode();
        }
        }
    
    async displayCategories(){
       //Display a category items 
        const api = await fetch(process.env.REACT_APP_API_BASE_URL +'/api/category')
        const data = await api.json()
      
        const categoryList = data.map((value)=>{
           return value.pr_cat_name
        })
        this.setState({categoryItems:categoryList})
        const newMenuOption = this.state.menuOption.map((items)=>{
              return {[Object.keys(items)[0]]:this.state.categoryItems}
      
        })
        this.setState({menuOption:newMenuOption})
      
       const tagApi = await fetch(process.env.REACT_APP_API_BASE_URL +'/api/admin/tags')
       const tagData = await tagApi.json()
       
        
      const wholeTag={};
      tagData.forEach(item=>{
        if(!wholeTag[item.tg_cat]){
            wholeTag[item.tg_cat] = [item.tg_tag_name]
        }
        else{
            wholeTag[item.tg_cat].push(item.tg_tag_name)
        }

      })
    
      this.setState({tagItems:[wholeTag]})
      this.megamenu();
    }
    fetchData = async (userIdControl) => {
        try {
            if (!userIdControl) userIdControl = '1';
            const response = await fetch(process.env.REACT_APP_API_BASE_URL +`/api/cart/find-by-user-id/${userIdControl}`);
            const data = await response.json();
            let dataLength = data.length ? data.length : 0;
            this.setState({ cartCount: dataLength });
        } catch (error) {
            this.setState({ cartCount: 0 });
        }
    };

    getCookiesCartList() {
        const cookies = new Cookies();
        let cartItems = cookies.get('cartItems') || [];
        this.setState({ cartCount: cartItems.length });

    }

    render() {
        const stickyheader = this.state.isTop ? 'sticky-active' : '';
        const { loggedOut, error, userName } = this.state;
  
        return (
            <Fragment>
                <header className={`header-absolute header-two plus-three sticky-header sigma-header ${stickyheader}`} id="header">
                    <div className="lr-topbar">
                        <div className="container container-custom-one">
                            <div className="row align-items-center">
                                <div className="col-lg-4">
                                    <div className="lr-topbar-controls">
                                        <ul>
                                            <li>
                                                <Link to="#">
                                                    Need Help? Call +91 90366 37777
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="lr-topbar-controls style-2">
                                        <ul>
                                            {/* <li>
                                                <div class="dropdown">
                                                    <form>
                                                        <select>
                                                            <option>Currency</option>
                                                        </select>
                                                    </form>
                                                </div>
                                            </li> */}
                                            <li>
                                                <Link to="/about-us">
                                                    About
                                                </Link>
                                            </li>
                                            {this.state.isLoggedIn && (
                                                <li>

                                                    <Link to="/account">
                                                        My account
                                                    </Link>

                                                </li>)}
                                            <li>
                                                {this.state.isLoggedIn ? (
                                                    <Link to="#" onClick={this.handleLogout}>
                                                        Logout
                                                    </Link>
                                                ) : (
                                                    <Link to="/login">
                                                        Login
                                                    </Link>
                                                )}
                                            </li>

                                            <li>
                                                {this.state.isLoggedIn ? ('') : (
                                                    <Link to="/register">
                                                        Signup
                                                    </Link>)}

                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container container-custom-one">
                        <div className="nav-container d-flex align-items-center">
                            {/* Main Menu */}
                            <div className="nav-menu d-lg-flex align-items-center justify-content-center">
                                {/* Navbar Close Icon */}
                                <div className="navbar-close">
                                    <div className="cross-wrap"><span className="top" /><span className="bottom" /></div>
                                </div>
                                {/* search btton */}
                                <div className="toggle">
                                    <Link to="#" id="offCanvasBtn" onClick={this.addClass}> <i className="fal fa-search" /></Link>
                                </div>
                                <div className="sigma-header-nav">
                                    <div className="sigma-header-nav-inner">
                                        <nav>
                                            <ul className="sigma-main-menu">
                                                {this.state.menuOption.map((item, i) => (
                                                    <li className="menu-item menu-item-has-children menu-item-has-megamenu">
                                                        <Link to="#">
                                                            {Object.keys(item)[0]}
                                                        </Link>
                                                        <div className="sub-menu">
                                                            <div className="container">
                                                                <div className="row">
                                                                    <div className="col-lg-3">
                                                                        <ul className="sigm-megamenu-nav nav nav-tabs" key={item}>
                                                                            {this.state.menuOption[i][Object.keys(item)[0]].map((element, k) => (
                                                                                <li className="nav-item" key={`${element}-${k}`}>
                                                                                    <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element })}`} className="nav-link" data-toggle="tab"><i className="fal fa-badge-check" /> {element}</Link>
                                                                                </li>
                                                                            ))}
                                                                            {/* <li className="nav-item">
                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({'material': item,'category': 'Earrings'})}`} className="nav-link" data-toggle="tab"><i className="fal fa-badge-check" /> Earrings</Link>
                                                                            </li>
                                                                            <li className="nav-item">
                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({'material': item,'category': 'Bracelets'})}`} className="nav-link" data-toggle="tab"><i className="fal fa-badge-check" /> Bracelets</Link>
                                                                            </li>
                                                                            <li className="nav-item">
                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({'material': item,'category': 'Pendants'})}`} className="nav-link" data-toggle="tab"><i className="fal fa-badge-check" /> Pendants</Link>
                                                                            </li>
                                                                            <li className="nav-item">
                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({'material': item,'category': 'Necklaces'})}`} className="nav-link" data-toggle="tab"><i className="fal fa-badge-check" /> Necklaces</Link>
                                                                            </li>
                                                                            <li className="nav-item">
                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({'material': item,'category': 'Daily Wears'})}`} className="nav-link" data-toggle="tab"><i className="fal fa-badge-check" />Daily Wears</Link>
                                                                            </li> */}
                                                                        </ul>
                                                                    </div>
                                                                    <div className="col-lg-9">
                                                                        {this.state.menuOption[i][Object.keys(item)[0]].map((element, k) => (
                                                                            <div className="tab-content">
                                                                                <div className="tab-item" id="tab">
                                                                                    <div className="row">
                                                                                        <div className="col-lg-3">
                                                                                            <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                                <h5 className="sigma-title">Shop by Style</h5>
                                                                                                <p >{element}</p>
                                                                                                <ul className="sub-menu">

                                                                                            
                                                                                                {this.state.tagItems[0] && this.state.tagItems[0][element] && (
                                                                                                    this.state.tagItems[0][element].map((tags,index)=>(
                                                                                                        <li className="menu-item" key={index}>
                                                                                                          <Link to={`/alljewellery/productfilter/${JSON.stringify({'material': Object.keys(item)[0],'category': element, 'tag': tags})}`}>{tags}</Link>
                                                                                                        </li>
                                                                                                    ))
                                                                                                )}
                                                                                                    {/* <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'tag': 'Engagement' })}`}>Engagement</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'tag': 'Daily Wear' })}`}>Daily Wear</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'tag': 'Couple Ring' })}`}>Couple Ring</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'tag': 'Platinum' })}`}>Platinum</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'tag': 'Bands' })}`}>Bands</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'tag': 'Promise Ring' })}`}>Promise Ring</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'tag': 'Adjustable Ring' })}`}>Adjustable Ring</Link>
                                                                                                    </li>*/}
                                                                                                </ul> 
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-3">
                                                                                            <div className="sigma-megamenu-navbox">
                                                                                                <h5 className="sigma-title">Shop by Metal & Stone</h5>
                                                                                                <div className="row mb-3">
                                                                                                    <div className="col-md-6 menu-item-has-children">
                                                                                                        <ul className="sub-menu">
                                                                                                            <li className="menu-item">
                                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'stone': 'Gem Stone' })}`}>Gem Stone</Link>
                                                                                                            </li>
                                                                                                            <li className="menu-item">
                                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'stone': 'Navaratna' })}`}>Navaratna</Link>
                                                                                                            </li>
                                                                                                            <li className="menu-item">
                                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'stone': 'Pearl' })}`}>Pearl</Link>
                                                                                                            </li>
                                                                                                            <li className="menu-item">
                                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'stone': 'Yellow Gold' })}`}>Yellow Gold</Link>
                                                                                                            </li>
                                                                                                            <li className="menu-item">
                                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'stone': 'Rose Gold' })}`}>Rose Gold</Link>
                                                                                                            </li>
                                                                                                            <li className="menu-item">
                                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'stone': 'White Gold' })}`}>White Gold</Link>
                                                                                                            </li>
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-2">
                                                                                            <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                                <h5 className="sigma-title">Shop by</h5>
                                                                                                <ul className="sub-menu">
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'maxPrice': '10000' })}`}>Under ₹ 10K</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'minPrice': '10000', 'maxPrice': '20000' })}`}>₹ 10K - ₹ 20K</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'minPrice': '20000', 'maxPrice': '30000' })}`}>₹ 20K - ₹ 30K</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'minPrice': '30000', 'maxPrice': '50000' })}`}>₹ 30K - ₹ 50K</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'minPrice': '50000', 'maxPrice': '75000' })}`}>₹ 50K - ₹ 75K</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'minPrice': '75000', 'maxPrice': '100000' })}`}>₹ 75K - ₹ 1 Lakh</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'minPrice': '100000' })}`}>Above ₹ 1 Lakh</Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-4">
                                                                                            <div className=" sigma-megamenu-navbox menu-item-has-children">
                                                                                                <ul className="sub-menu">
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="/custom-design">
                                                                                                            <img src={custom} />
                                                                                                        </Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                                <h5 id='customtag' className='text-center mt-3 text-dark'>Customize Jewel as you Wish.</h5>
                                                                                                <ul className="float-right mt-3">
                                                                                                    <li className="custom-item menu-item" id="custom-item">
                                                                                                        <Link to="/custom-design">
                                                                                                            Start Customizing<i class="fas fa-arrow-right ml-3"></i>
                                                                                                        </Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* <div className="tab-item" id="tab2">
                                                                                    <div className="row">
                                                                                        <div className="col-lg-3">
                                                                                            <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                                <h5 className="sigma-title">Shop by Style</h5>
                                                                                                <ul className="sub-menu">
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'tag': 'Studs' })}`}>Studs</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'tag': 'Jhumkas' })}`}>Jhumkas</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'tag': 'Ear Cuffs' })}`}>Ear Cuffs</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'tag': 'Pearl Ear Rings' })}`}>Pearl Ear Rings</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'tag': 'Gold Ear Rings' })}`}>Gold Ear Rings</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'tag': 'Drops' })}`}>Drops</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'tag': 'Daily Wear' })}`}>Daily Wear</Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-3">
                                                                                            <div className="sigma-megamenu-navbox">
                                                                                                <h5 className="sigma-title">Shop by Metal & Stone</h5>
                                                                                                <div className="row mb-3">
                                                                                                    <div className="col-md-6 menu-item-has-children">
                                                                                                        <ul className="sub-menu">
                                                                                                            <li className="menu-item">
                                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'stone': 'Solitaire' })}`}>Solitaire</Link>
                                                                                                            </li>
                                                                                                            <li className="menu-item">
                                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'stone': '22KT Gold' })}`}>22KT Gold</Link>
                                                                                                            </li>
                                                                                                            <li className="menu-item">
                                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'stone': 'Gem Stone' })}`}>Gem Stone</Link>
                                                                                                            </li>
                                                                                                            <li className="menu-item">
                                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'stone': 'Pearl' })}`}>Pearl</Link>
                                                                                                            </li>
                                                                                                            <li className="menu-item">
                                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'stone': 'Yellow Gold' })}`}>Yellow Gold</Link>
                                                                                                            </li>
                                                                                                            <li className="menu-item">
                                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'stone': 'Rose Gold' })}`}>Rose Gold</Link>
                                                                                                            </li>
                                                                                                            <li className="menu-item">
                                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'stone': 'White Gold' })}`}>White Gold</Link>
                                                                                                            </li>
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-2">
                                                                                            <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                                <h5 className="sigma-title">Shop by</h5>
                                                                                                <ul className="sub-menu">
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'maxPrice': '10000' })}`}>Under ₹ 10K</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'minPrice': '10000', 'maxPrice': '20000' })}`}>₹ 10K - ₹ 20K</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'minPrice': '20000', 'maxPrice': '30000' })}`}>₹ 20K - ₹ 30K</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'minPrice': '30000', 'maxPrice': '50000' })}`}>₹ 30K - ₹ 50K</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'minPrice': '50000', 'maxPrice': '75000' })}`}>₹ 50K - ₹ 75K</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'minPrice': '75000', 'maxPrice': '100000' })}`}>₹ 75K - ₹ 1 Lakh</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'minPrice': '100000' })}`}>Above ₹ 1 Lakh</Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-4">
                                                                                            <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                                <ul className="sub-menu">
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="/custom-design">
                                                                                                            <img src={custom} />
                                                                                                        </Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                                <h5 className='text-center mt-3'>Customize Jewel as you Wish.</h5>
                                                                                                <ul className="float-right mt-3">
                                                                                                    <li className="custom-item menu-item" id="custom-item">
                                                                                                        <Link to="/custom-design">
                                                                                                            Start Customizing<i class="fas fa-arrow-right ml-3"></i>
                                                                                                        </Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="tab-item" id="tab3">
                                                                                    <div className="row">
                                                                                        <div className="col-lg-3">
                                                                                            <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                                <h5 className="sigma-title">Shop by Style</h5>
                                                                                                <ul className="sub-menu">
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'tag': 'Chain Bracelets' })}`}>Chain Bracelets</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'tag': 'Flexible Bracelets' })}`}>Flexible Bracelets</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'tag': 'Lightwear Bangles' })}`}>Lightwear Bangles</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'tag': 'kids Bracelets' })}`}>kids Bracelets</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'tag': 'Oval bracelets' })}`}>Oval bracelets</Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-3">
                                                                                            <div className="sigma-megamenu-navbox">
                                                                                                <h5 className="sigma-title">Shop by Metal & Stone</h5>
                                                                                                <div className="row mb-3">
                                                                                                    <div className="col-md-6 menu-item-has-children">
                                                                                                        <ul className="sub-menu">
                                                                                                            <li className="menu-item">
                                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'stone': 'Diamond' })}`}>Diamond</Link>
                                                                                                            </li>
                                                                                                            <li className="menu-item">
                                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'stone': 'Gem Stone' })}`}>Gem Stone</Link>
                                                                                                            </li>

                                                                                                            <li className="menu-item">
                                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'stone': 'Pearl' })}`}>Pearl</Link>
                                                                                                            </li>
                                                                                                            <li className="menu-item">
                                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'stone': 'Yellow Gold' })}`}>Yellow Gold</Link>
                                                                                                            </li>
                                                                                                            <li className="menu-item">
                                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'stone': 'Rose Gold' })}`}>Rose Gold</Link>
                                                                                                            </li>
                                                                                                            <li className="menu-item">
                                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'stone': 'White Gold' })}`}>White Gold</Link>
                                                                                                            </li>
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-2">
                                                                                            <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                                <h5 className="sigma-title">Shop by</h5>
                                                                                                <ul className="sub-menu">
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'maxPrice': '10000' })}`}>Under ₹ 10K</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'minPrice': '10000', 'maxPrice': '20000' })}`}>₹ 10K - ₹ 20K</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'minPrice': '20000', 'maxPrice': '30000' })}`}>₹ 20K - ₹ 30K</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'minPrice': '30000', 'maxPrice': '50000' })}`}>₹ 30K - ₹ 50K</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'minPrice': '50000', 'maxPrice': '75000' })}`}>₹ 50K - ₹ 75K</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'minPrice': '75000', 'maxPrice': '100000' })}`}>₹ 75K - ₹ 1 Lakh</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'minPrice': '100000' })}`}>Above ₹ 1 Lakh</Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-4">
                                                                                            <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                                <ul className="sub-menu">
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="/custom-design">
                                                                                                            <img src={custom} />
                                                                                                        </Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                                <h5 className='text-center mt-3'>Customize Jewel as you Wish.</h5>
                                                                                                <ul className="float-right mt-3">
                                                                                                    <li className="custom-item menu-item" id="custom-item">
                                                                                                        <Link to="/custom-design">
                                                                                                            Start Customizing<i class="fas fa-arrow-right ml-3"></i>
                                                                                                        </Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="tab-item" id="tab4">
                                                                                    <div className="row">
                                                                                        <div className="col-lg-3">
                                                                                            <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                                <h5 className="sigma-title">Shop by Style</h5>
                                                                                                <ul className="sub-menu">
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'tag': 'Round' })}`}>Round</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'tag': 'Pear' })}`}>Pear</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'tag': 'Heart' })}`}>Heart</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'tag': 'Princess' })}`}>Princess</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'tag': 'Oval' })}`}>Oval</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'tag': 'Cusion' })}`}>Cusion</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'tag': 'Emerald' })}`}>Emerald</Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-3">
                                                                                            <div className="sigma-megamenu-navbox">
                                                                                                <h5 className="sigma-title">Shop by Metal & Stone</h5>
                                                                                                <div className="row mb-3">
                                                                                                    <div className="col-md-6 menu-item-has-children">
                                                                                                        <ul className="sub-menu">

                                                                                                            <li className="menu-item">
                                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'stone': 'Diamond' })}`}>Diamond</Link>
                                                                                                            </li>
                                                                                                            <li className="menu-item">
                                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'stone': 'Gem Stone' })}`}>Gem Stone</Link>
                                                                                                            </li>

                                                                                                            <li className="menu-item">
                                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'stone': 'Pearl' })}`}>Pearl</Link>
                                                                                                            </li>
                                                                                                            <li className="menu-item">
                                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'stone': 'Yellow Gold' })}`}>Yellow Gold</Link>
                                                                                                            </li>
                                                                                                            <li className="menu-item">
                                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'stone': 'Rose Gold' })}`}>Rose Gold</Link>
                                                                                                            </li>
                                                                                                            <li className="menu-item">
                                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'stone': 'White Gold' })}`}>White Gold</Link>
                                                                                                            </li>
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-2">
                                                                                            <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                                <h5 className="sigma-title">Shop by</h5>
                                                                                                <ul className="sub-menu">
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'maxPrice': '10000' })}`}>Under ₹ 10K</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'minPrice': '10000', 'maxPrice': '20000' })}`}>₹ 10K - ₹ 20K</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'minPrice': '20000', 'maxPrice': '30000' })}`}>₹ 20K - ₹ 30K</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'minPrice': '30000', 'maxPrice': '50000' })}`}>₹ 30K - ₹ 50K</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'minPrice': '50000', 'maxPrice': '75000' })}`}>₹ 50K - ₹ 75K</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'minPrice': '75000', 'maxPrice': '100000' })}`}>₹ 75K - ₹ 1 Lakh</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'minPrice': '100000' })}`}>Above ₹ 1 Lakh</Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-4">
                                                                                            <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                                <ul className="sub-menu">
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="/custom-design">
                                                                                                            <img src={custom} />
                                                                                                        </Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                                <h5 className='text-center mt-3'>Customize Jewel as you Wish.</h5>
                                                                                                <ul className="float-right mt-3">
                                                                                                    <li className="custom-item menu-item" id="custom-item">
                                                                                                        <Link to="/custom-design">
                                                                                                            Start Customizing<i class="fas fa-arrow-right ml-3"></i>
                                                                                                        </Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="tab-item" id="tab5">
                                                                                    <div className="row">
                                                                                        <div className="col-lg-3">
                                                                                            <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                                <h5 className="sigma-title">Shop by Style</h5>
                                                                                                <ul className="sub-menu">
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'tag': 'Fashion' })}`}>Fashion</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'tag': 'Solitaire' })}`}>Solitaire</Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-3">
                                                                                            <div className="sigma-megamenu-navbox">
                                                                                                <h5 className="sigma-title">Shop by Metal & Stone</h5>
                                                                                                <div className="row mb-3">
                                                                                                    <div className="col-md-6 menu-item-has-children">
                                                                                                        <ul className="sub-menu">

                                                                                                            <li className="menu-item">
                                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'stone': 'Diamond' })}`}>Diamond</Link>
                                                                                                            </li>
                                                                                                            <li className="menu-item">
                                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'stone': 'Gem Stone' })}`}>Gem Stone</Link>
                                                                                                            </li>

                                                                                                            <li className="menu-item">
                                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'stone': 'Pearl' })}`}>Pearl</Link>
                                                                                                            </li>
                                                                                                            <li className="menu-item">
                                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'stone': 'Yellow Gold' })}`}>Yellow Gold</Link>
                                                                                                            </li>
                                                                                                            <li className="menu-item">
                                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'stone': 'Rose Gold' })}`}>Rose Gold</Link>
                                                                                                            </li>
                                                                                                            <li className="menu-item">
                                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'stone': 'White Gold' })}`}>White Gold</Link>
                                                                                                            </li>
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-2">
                                                                                            <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                                <h5 className="sigma-title">Shop by</h5>
                                                                                                <ul className="sub-menu">
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'maxPrice': '10000' })}`}>Under ₹ 10K</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'minPrice': '10000', 'maxPrice': '20000' })}`}>₹ 10K - ₹ 20K</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'minPrice': '20000', 'maxPrice': '30000' })}`}>₹ 20K - ₹ 30K</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'minPrice': '30000', 'maxPrice': '50000' })}`}>₹ 30K - ₹ 50K</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'minPrice': '50000', 'maxPrice': '75000' })}`}>₹ 50K - ₹ 75K</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'minPrice': '75000', 'maxPrice': '100000' })}`}>₹ 75K - ₹ 1 Lakh</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'minPrice': '100000' })}`}>Above ₹ 1 Lakh</Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-4">
                                                                                            <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                                <ul className="sub-menu">
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="/custom-design">
                                                                                                            <img src={custom} />
                                                                                                        </Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                                <h5 className='text-center mt-3'>Customize Jewel as you Wish.</h5>
                                                                                                <ul className="float-right mt-3">
                                                                                                    <li className="custom-item menu-item" id="custom-item">
                                                                                                        <Link to="/custom-design">
                                                                                                            Start Customizing<i class="fas fa-arrow-right ml-3"></i>
                                                                                                        </Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="tab-item" id='#tab6'>
                                                                                    <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                        <ul className="sub-menu d-flex">
                                                                                            <li className="menu-item">
                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'dailyWear': 'Rings' })}`}><img src={daily1} /></Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'dailyWear': 'Ear Rings' })}`}><img src={daily2} /></Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'dailyWear': 'Chains' })}`}><img src={daily3} /></Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'dailyWear': 'Anklets' })}`}><img src={daily4} /></Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                    <div className='col-12'> */}
                                                                                        {/* <div className='daily-wear'>
                                                                                                <ul className='d-flex'>
                                                                                                    <li>
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'dailyWear': 'Rings' })}`}>
                                                                                                            <img src={daily1} alt='' />
                                                                                                            <h4>Rings</h4>
                                                                                                        </Link>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'dailyWear': 'Ear Rings' })}`}>
                                                                                                            <img src={daily2} alt='' />
                                                                                                            <h4>Ear Rings</h4>
                                                                                                        </Link>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'dailyWear': 'Chains' })}`}>
                                                                                                            <img src={daily3} alt='' />
                                                                                                            <h4>Chains</h4>
                                                                                                        </Link>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <Link to={`/alljewellery/productfilter/${JSON.stringify({ 'material': Object.keys(item)[0], 'category': element, 'dailyWear': 'Anklets' })}`}>
                                                                                                            <img src={daily4} alt='' />
                                                                                                            <h4>Anklets</h4>
                                                                                                        </Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div> */}
                                                                                    {/* </div>
                                                                                </div> */}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                                {/* <li className="menu-item menu-item-has-children menu-item-has-megamenu">
                                                    <Link to="#">
                                                        Diamond
                                                    </Link>
                                                    <div className="sub-menu">
                                                        <div className="container">
                                                            <div className="row">
                                                                <div className="col-lg-3">
                                                                    <ul className="sigm-megamenu-nav nav nav-tabs">
                                                                        <li className="nav-item">
                                                                            <Link to="/alljewellery/Diamond/Rings" className="nav-link active" data-toggle="tab"><i className="fal fa-badge-check" /> Rings</Link>
                                                                        </li>
                                                                        <li className="nav-item">
                                                                            <Link to="/alljewellery/Diamond/Earrings" className="nav-link" data-toggle="tab"><i className="fal fa-badge-check" /> Earrings</Link>
                                                                        </li>
                                                                        <li className="nav-item">
                                                                            <Link to="/alljewellery/Diamond/Bracelets" className="nav-link" data-toggle="tab"><i className="fal fa-badge-check" /> Bracelets</Link>
                                                                        </li>
                                                                        <li className="nav-item">
                                                                            <Link to="/alljewellery/Diamond/Pendants" className="nav-link" data-toggle="tab"><i className="fal fa-badge-check" /> Pendants</Link>
                                                                        </li>
                                                                        <li className="nav-item">
                                                                            <Link to="/alljewellery/Diamond/Necklaces" className="nav-link" data-toggle="tab"><i className="fal fa-badge-check" /> Necklaces</Link>
                                                                        </li>
                                                                        <li className="nav-item">
                                                                            <Link to="/alljewellery/Diamond/Daily Wears" className="nav-link" data-toggle="tab"><i className="fal fa-badge-check" />Daily Wears</Link>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-lg-9">
                                                                    <div className="tab-content">
                                                                        <div className="tab-item" id="tab7">
                                                                            <div className="row">
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                        <h5 className="sigma-title">Shop by Style</h5>
                                                                                        <ul className="sub-menu">
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Engagement</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Daily Wear</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Couple Ring</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Platinum</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Bands</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Promise Ring</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Adjustable Ring</Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox">
                                                                                        <h5 className="sigma-title">Shop by Metal & Stone</h5>
                                                                                        <div className="row mb-3">
                                                                                            <div className="col-md-6 menu-item-has-children">
                                                                                                <ul className="sub-menu">
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Gem Stone</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Navaratna</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Pearl</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Yellow Gold</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Rose Gold</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">White Gold</Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                        <h5 className="sigma-title">Shop by</h5>
                                                                                        <ul className="sub-menu">
                                                                                            <li className="menu-item">
                                                                                                <Link to="/jewellery-by-price/0/10000">Under ₹ 10K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="/jewellery-by-price/10000/20000">₹ 10K - ₹ 20K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="/jewellery-by-price/20000/30000">₹ 20K - ₹ 30K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="/jewellery-by-price/30000/50000">₹ 30K - ₹ 50K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="/jewellery-by-price/50000/75000">₹ 50K - ₹ 75K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="/jewellery-by-price/75000/100000">₹ 75K - ₹ 1 Lakh</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="/jewellery-by-price/100000/100000000000000">Above ₹ 1 Lakh</Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="tab-item" id="tab8">
                                                                            <div className="row">
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                        <h5 className="sigma-title">Shop by Style</h5>
                                                                                        <ul className="sub-menu">
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Studs</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Jhumkas</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Ear Cuffs</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Pearl Ear Rings</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Gold Ear Rings</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Drops</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Daily Wear</Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox">
                                                                                        <h5 className="sigma-title">Shop by Metal & Stone</h5>
                                                                                        <div className="row mb-3">
                                                                                            <div className="col-md-6 menu-item-has-children">
                                                                                                <ul className="sub-menu">
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Solitaire</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">22KT Gold</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Gem Stone</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Pearl</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Yellow Gold</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Rose Gold</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">White Gold</Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                        <h5 className="sigma-title">Shop by</h5>
                                                                                        <ul className="sub-menu">
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Under ₹ 10K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 10K - ₹ 20K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 20K - ₹ 30K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 30K - ₹ 50K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 50K - ₹ 75K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 75K - ₹ 1 Lakh</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Above ₹ 1 Lakh</Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="tab-item" id="tab9">
                                                                            <div className="row">
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                        <h5 className="sigma-title">Shop by Style</h5>
                                                                                        <ul className="sub-menu">
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Chain Bracelets</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Flexible Bracelets</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Lightwear Bangles</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">kids Bracelets</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Oval bracelets</Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox">
                                                                                        <h5 className="sigma-title">Shop by Metal & Stone</h5>
                                                                                        <div className="row mb-3">
                                                                                            <div className="col-md-6 menu-item-has-children">
                                                                                                <ul className="sub-menu">
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Diamond</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Gem Stone</Link>
                                                                                                    </li>

                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Pearl</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Yellow Gold</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Rose Gold</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">White Gold</Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                        <h5 className="sigma-title">Shop by</h5>
                                                                                        <ul className="sub-menu">
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Under ₹ 10K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 10K - ₹ 20K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 20K - ₹ 30K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 30K - ₹ 50K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 50K - ₹ 75K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 75K - ₹ 1 Lakh</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Above ₹ 1 Lakh</Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="tab-item" id="tab10">
                                                                            <div className="row">
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                        <h5 className="sigma-title">Shop by Style</h5>
                                                                                        <ul className="sub-menu">
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Round</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Pear</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Heart</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Princess</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Oval</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Cusion</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Emerald</Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox">
                                                                                        <h5 className="sigma-title">Shop by Metal & Stone</h5>
                                                                                        <div className="row mb-3">
                                                                                            <div className="col-md-6 menu-item-has-children">
                                                                                                <ul className="sub-menu">

                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Diamond</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Gem Stone</Link>
                                                                                                    </li>

                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Pearl</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Yellow Gold</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Rose Gold</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">White Gold</Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                        <h5 className="sigma-title">Shop by</h5>
                                                                                        <ul className="sub-menu">
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Under ₹ 10K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 10K - ₹ 20K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 20K - ₹ 30K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 30K - ₹ 50K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 50K - ₹ 75K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 75K - ₹ 1 Lakh</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Above ₹ 1 Lakh</Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="tab-item" id="tab11">
                                                                            <div className="row">
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                        <h5 className="sigma-title">Shop by Style</h5>
                                                                                        <ul className="sub-menu">
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Fashion</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Solitaire</Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox">
                                                                                        <h5 className="sigma-title">Shop by Metal & Stone</h5>
                                                                                        <div className="row mb-3">
                                                                                            <div className="col-md-6 menu-item-has-children">
                                                                                                <ul className="sub-menu">

                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Diamond</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Gem Stone</Link>
                                                                                                    </li>

                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Pearl</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Yellow Gold</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Rose Gold</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">White Gold</Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                        <h5 className="sigma-title">Shop by</h5>
                                                                                        <ul className="sub-menu">
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Under ₹ 10K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 10K - ₹ 20K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 20K - ₹ 30K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 30K - ₹ 50K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 50K - ₹ 75K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 75K - ₹ 1 Lakh</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Above ₹ 1 Lakh</Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="tab-item" id='#tab12'>
                                                                            <div className="row">
                                                                                
                                                                                <div className='col-12'>
                                                                                    <div className='daily-wear'>
                                                                                        <ul className='d-flex'>
                                                                                            <li>
                                                                                                <Link to="/allJewellery">
                                                                                                    <img src={daily1} alt='' />
                                                                                                    <h4>Rings</h4>
                                                                                                </Link>
                                                                                            </li>
                                                                                            <li>
                                                                                                <Link to="/allJewellery">
                                                                                                    <img src={daily2} alt='' />
                                                                                                    <h4>Ear Rings</h4>
                                                                                                </Link>
                                                                                            </li>
                                                                                            <li>
                                                                                                <Link to="/allJewellery">
                                                                                                    <img src={daily3} alt='' />
                                                                                                    <h4>Chains</h4>
                                                                                                </Link>
                                                                                            </li>
                                                                                            <li>
                                                                                                <Link to="/allJewellery">
                                                                                                    <img src={daily4} alt='' />
                                                                                                    <h4>Anklets</h4>
                                                                                                </Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li> */}
                                                {/* <li className="menu-item menu-item-has-children menu-item-has-megamenu">
                                                    <Link to="#">
                                                        Platinum
                                                    </Link>
                                                    <div className="sub-menu">
                                                        <div className="container">
                                                            <div className="row">
                                                                <div className="col-lg-3">
                                                                    <ul className="sigm-megamenu-nav nav nav-tabs">
                                                                        <li className="nav-item">
                                                                            <Link to="/alljewellery/Platinum/Rings" className="nav-link active" data-toggle="tab"><i className="fal fa-badge-check" /> Rings</Link>
                                                                        </li>
                                                                        <li className="nav-item">
                                                                            <Link to="/alljewellery/Platinum/Earrings" className="nav-link" data-toggle="tab"><i className="fal fa-badge-check" /> Earrings</Link>
                                                                        </li>
                                                                        <li className="nav-item">
                                                                            <Link to="/alljewellery/Platinum/Bracelets" className="nav-link" data-toggle="tab"><i className="fal fa-badge-check" /> Bracelets</Link>
                                                                        </li>
                                                                        <li className="nav-item">
                                                                            <Link to="/alljewellery/Platinum/Pendants" className="nav-link" data-toggle="tab"><i className="fal fa-badge-check" /> Pendants</Link>
                                                                        </li>
                                                                        <li className="nav-item">
                                                                            <Link to="/alljewellery/Platinum/Necklaces" className="nav-link" data-toggle="tab"><i className="fal fa-badge-check" /> Necklaces</Link>
                                                                        </li>
                                                                        <li className="nav-item">
                                                                            <Link to="/alljewellery/Platinum/Daily Wears" className="nav-link" data-toggle="tab"><i className="fal fa-badge-check" />Daily Wears</Link>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-lg-9">
                                                                    <div className="tab-content">
                                                                        <div className="tab-item" id="tab1">
                                                                            <div className="row">
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                        <h5 className="sigma-title">Shop by Style</h5>
                                                                                        <ul className="sub-menu">
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Engagement</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Daily Wear</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Couple Ring</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Platinum</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Bands</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Promise Ring</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Adjustable Ring</Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox">
                                                                                        <h5 className="sigma-title">Shop by Metal & Stone</h5>
                                                                                        <div className="row mb-3">
                                                                                            <div className="col-md-6 menu-item-has-children">
                                                                                                <ul className="sub-menu">
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Gem Stone</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Navaratna</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Pearl</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Yellow Gold</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Rose Gold</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">White Gold</Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                        <h5 className="sigma-title">Shop by</h5>
                                                                                        <ul className="sub-menu">
                                                                                            <li className="menu-item">
                                                                                                <Link to="/jewellery-by-price/0/10000">Under ₹ 10K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="/jewellery-by-price/10000/20000">₹ 10K - ₹ 20K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="/jewellery-by-price/20000/30000">₹ 20K - ₹ 30K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="/jewellery-by-price/30000/50000">₹ 30K - ₹ 50K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="/jewellery-by-price/50000/75000">₹ 50K - ₹ 75K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="/jewellery-by-price/75000/100000">₹ 75K - ₹ 1 Lakh</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="/jewellery-by-price/100000/100000000000000">Above ₹ 1 Lakh</Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="tab-item" id="tab2">
                                                                            <div className="row">
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                        <h5 className="sigma-title">Shop by Style</h5>
                                                                                        <ul className="sub-menu">
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Studs</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Jhumkas</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Ear Cuffs</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Pearl Ear Rings</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Gold Ear Rings</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Drops</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Daily Wear</Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox">
                                                                                        <h5 className="sigma-title">Shop by Metal & Stone</h5>
                                                                                        <div className="row mb-3">
                                                                                            <div className="col-md-6 menu-item-has-children">
                                                                                                <ul className="sub-menu">
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Solitaire</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">22KT Gold</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Gem Stone</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Pearl</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Yellow Gold</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Rose Gold</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">White Gold</Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                        <h5 className="sigma-title">Shop by</h5>
                                                                                        <ul className="sub-menu">
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Under ₹ 10K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 10K - ₹ 20K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 20K - ₹ 30K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 30K - ₹ 50K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 50K - ₹ 75K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 75K - ₹ 1 Lakh</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Above ₹ 1 Lakh</Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="tab-item" id="tab3">
                                                                            <div className="row">
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                        <h5 className="sigma-title">Shop by Style</h5>
                                                                                        <ul className="sub-menu">
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Chain Bracelets</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Flexible Bracelets</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Lightwear Bangles</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">kids Bracelets</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Oval bracelets</Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox">
                                                                                        <h5 className="sigma-title">Shop by Metal & Stone</h5>
                                                                                        <div className="row mb-3">
                                                                                            <div className="col-md-6 menu-item-has-children">
                                                                                                <ul className="sub-menu">
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Diamond</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Gem Stone</Link>
                                                                                                    </li>

                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Pearl</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Yellow Gold</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Rose Gold</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">White Gold</Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                        <h5 className="sigma-title">Shop by</h5>
                                                                                        <ul className="sub-menu">
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Under ₹ 10K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 10K - ₹ 20K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 20K - ₹ 30K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 30K - ₹ 50K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 50K - ₹ 75K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 75K - ₹ 1 Lakh</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Above ₹ 1 Lakh</Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="tab-item" id="tab4">
                                                                            <div className="row">
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                        <h5 className="sigma-title">Shop by Style</h5>
                                                                                        <ul className="sub-menu">
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Round</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Pear</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Heart</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Princess</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Oval</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Cusion</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Emerald</Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox">
                                                                                        <h5 className="sigma-title">Shop by Metal & Stone</h5>
                                                                                        <div className="row mb-3">
                                                                                            <div className="col-md-6 menu-item-has-children">
                                                                                                <ul className="sub-menu">

                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Diamond</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Gem Stone</Link>
                                                                                                    </li>

                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Pearl</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Yellow Gold</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Rose Gold</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">White Gold</Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                        <h5 className="sigma-title">Shop by</h5>
                                                                                        <ul className="sub-menu">
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Under ₹ 10K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 10K - ₹ 20K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 20K - ₹ 30K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 30K - ₹ 50K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 50K - ₹ 75K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 75K - ₹ 1 Lakh</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Above ₹ 1 Lakh</Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="tab-item" id="tab5">
                                                                            <div className="row">
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                        <h5 className="sigma-title">Shop by Style</h5>
                                                                                        <ul className="sub-menu">
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Fashion</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Solitaire</Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox">
                                                                                        <h5 className="sigma-title">Shop by Metal & Stone</h5>
                                                                                        <div className="row mb-3">
                                                                                            <div className="col-md-6 menu-item-has-children">
                                                                                                <ul className="sub-menu">

                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Diamond</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Gem Stone</Link>
                                                                                                    </li>

                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Pearl</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Yellow Gold</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Rose Gold</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">White Gold</Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                        <h5 className="sigma-title">Shop by</h5>
                                                                                        <ul className="sub-menu">
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Under ₹ 10K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 10K - ₹ 20K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 20K - ₹ 30K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 30K - ₹ 50K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 50K - ₹ 75K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 75K - ₹ 1 Lakh</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Above ₹ 1 Lakh</Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="tab-item" id='#tab6'>
                                                                            <div className="row">
                                                                                
                                                                                <div className='col-12'>
                                                                                    <div className='daily-wear'>
                                                                                        <ul className='d-flex'>
                                                                                            <li>
                                                                                                <Link to="/allJewellery">
                                                                                                    <img src={daily1} alt='' />
                                                                                                    <h4>Rings</h4>
                                                                                                </Link>
                                                                                            </li>
                                                                                            <li>
                                                                                                <Link to="/allJewellery">
                                                                                                    <img src={daily2} alt='' />
                                                                                                    <h4>Ear Rings</h4>
                                                                                                </Link>
                                                                                            </li>
                                                                                            <li>
                                                                                                <Link to="/allJewellery">
                                                                                                    <img src={daily3} alt='' />
                                                                                                    <h4>Chains</h4>
                                                                                                </Link>
                                                                                            </li>
                                                                                            <li>
                                                                                                <Link to="/allJewellery">
                                                                                                    <img src={daily4} alt='' />
                                                                                                    <h4>Anklets</h4>
                                                                                                </Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li> */}
                                                {/* <li className="menu-item menu-item-has-children menu-item-has-megamenu">
                                                    <Link to="#">
                                                        Silver
                                                    </Link>
                                                    <div className="sub-menu">
                                                        <div className="container">
                                                            <div className="row">
                                                                <div className="col-lg-3">
                                                                    <ul className="sigm-megamenu-nav nav nav-tabs">
                                                                        <li className="nav-item">
                                                                            <Link to="/alljewellery/Silver/Rings" className="nav-link active" data-toggle="tab"><i className="fal fa-badge-check" /> Rings</Link>
                                                                        </li>
                                                                        <li className="nav-item">
                                                                            <Link to="/alljewellery/Silver/Earrings" className="nav-link" data-toggle="tab"><i className="fal fa-badge-check" /> Earrings</Link>
                                                                        </li>
                                                                        <li className="nav-item">
                                                                            <Link to="/alljewellery/Silver/Bracelets" className="nav-link" data-toggle="tab"><i className="fal fa-badge-check" /> Bracelets</Link>
                                                                        </li>
                                                                        <li className="nav-item">
                                                                            <Link to="/alljewellery/Silver/Pendants" className="nav-link" data-toggle="tab"><i className="fal fa-badge-check" /> Pendants</Link>
                                                                        </li>
                                                                        <li className="nav-item">
                                                                            <Link to="/alljewellery/Silver/Necklaces" className="nav-link" data-toggle="tab"><i className="fal fa-badge-check" /> Necklaces</Link>
                                                                        </li>
                                                                        <li className="nav-item">
                                                                            <Link to="/alljewellery/Silver/Daily Wears" className="nav-link" data-toggle="tab"><i className="fal fa-badge-check" />Daily Wears</Link>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-lg-9">
                                                                    <div className="tab-content">
                                                                        <div className="tab-item" id="tab1">
                                                                            <div className="row">
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                        <h5 className="sigma-title">Shop by Style</h5>
                                                                                        <ul className="sub-menu">
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Engagement</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Daily Wear</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Couple Ring</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Platinum</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Bands</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Promise Ring</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Adjustable Ring</Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox">
                                                                                        <h5 className="sigma-title">Shop by Metal & Stone</h5>
                                                                                        <div className="row mb-3">
                                                                                            <div className="col-md-6 menu-item-has-children">
                                                                                                <ul className="sub-menu">
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Gem Stone</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Navaratna</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Pearl</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Yellow Gold</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Rose Gold</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">White Gold</Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                        <h5 className="sigma-title">Shop by</h5>
                                                                                        <ul className="sub-menu">
                                                                                            <li className="menu-item">
                                                                                                <Link to="/jewellery-by-price/0/10000">Under ₹ 10K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="/jewellery-by-price/10000/20000">₹ 10K - ₹ 20K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="/jewellery-by-price/20000/30000">₹ 20K - ₹ 30K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="/jewellery-by-price/30000/50000">₹ 30K - ₹ 50K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="/jewellery-by-price/50000/75000">₹ 50K - ₹ 75K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="/jewellery-by-price/75000/100000">₹ 75K - ₹ 1 Lakh</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="/jewellery-by-price/100000/100000000000000">Above ₹ 1 Lakh</Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="tab-item" id="tab2">
                                                                            <div className="row">
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                        <h5 className="sigma-title">Shop by Style</h5>
                                                                                        <ul className="sub-menu">
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Studs</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Jhumkas</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Ear Cuffs</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Pearl Ear Rings</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Gold Ear Rings</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Drops</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Daily Wear</Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox">
                                                                                        <h5 className="sigma-title">Shop by Metal & Stone</h5>
                                                                                        <div className="row mb-3">
                                                                                            <div className="col-md-6 menu-item-has-children">
                                                                                                <ul className="sub-menu">
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Solitaire</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">22KT Gold</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Gem Stone</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Pearl</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Yellow Gold</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Rose Gold</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">White Gold</Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                        <h5 className="sigma-title">Shop by</h5>
                                                                                        <ul className="sub-menu">
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Under ₹ 10K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 10K - ₹ 20K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 20K - ₹ 30K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 30K - ₹ 50K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 50K - ₹ 75K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 75K - ₹ 1 Lakh</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Above ₹ 1 Lakh</Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="tab-item" id="tab3">
                                                                            <div className="row">
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                        <h5 className="sigma-title">Shop by Style</h5>
                                                                                        <ul className="sub-menu">
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Chain Bracelets</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Flexible Bracelets</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Lightwear Bangles</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">kids Bracelets</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Oval bracelets</Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox">
                                                                                        <h5 className="sigma-title">Shop by Metal & Stone</h5>
                                                                                        <div className="row mb-3">
                                                                                            <div className="col-md-6 menu-item-has-children">
                                                                                                <ul className="sub-menu">
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Diamond</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Gem Stone</Link>
                                                                                                    </li>

                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Pearl</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Yellow Gold</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Rose Gold</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">White Gold</Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                        <h5 className="sigma-title">Shop by</h5>
                                                                                        <ul className="sub-menu">
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Under ₹ 10K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 10K - ₹ 20K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 20K - ₹ 30K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 30K - ₹ 50K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 50K - ₹ 75K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 75K - ₹ 1 Lakh</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Above ₹ 1 Lakh</Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="tab-item" id="tab4">
                                                                            <div className="row">
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                        <h5 className="sigma-title">Shop by Style</h5>
                                                                                        <ul className="sub-menu">
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Round</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Pear</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Heart</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Princess</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Oval</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Cusion</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Emerald</Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox">
                                                                                        <h5 className="sigma-title">Shop by Metal & Stone</h5>
                                                                                        <div className="row mb-3">
                                                                                            <div className="col-md-6 menu-item-has-children">
                                                                                                <ul className="sub-menu">

                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Diamond</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Gem Stone</Link>
                                                                                                    </li>

                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Pearl</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Yellow Gold</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Rose Gold</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">White Gold</Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                        <h5 className="sigma-title">Shop by</h5>
                                                                                        <ul className="sub-menu">
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Under ₹ 10K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 10K - ₹ 20K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 20K - ₹ 30K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 30K - ₹ 50K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 50K - ₹ 75K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 75K - ₹ 1 Lakh</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Above ₹ 1 Lakh</Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="tab-item" id="tab5">
                                                                            <div className="row">
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                        <h5 className="sigma-title">Shop by Style</h5>
                                                                                        <ul className="sub-menu">
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Fashion</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Solitaire</Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox">
                                                                                        <h5 className="sigma-title">Shop by Metal & Stone</h5>
                                                                                        <div className="row mb-3">
                                                                                            <div className="col-md-6 menu-item-has-children">
                                                                                                <ul className="sub-menu">

                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Diamond</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Gem Stone</Link>
                                                                                                    </li>

                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Pearl</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Yellow Gold</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">Rose Gold</Link>
                                                                                                    </li>
                                                                                                    <li className="menu-item">
                                                                                                        <Link to="">White Gold</Link>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="sigma-megamenu-navbox menu-item-has-children">
                                                                                        <h5 className="sigma-title">Shop by</h5>
                                                                                        <ul className="sub-menu">
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Under ₹ 10K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 10K - ₹ 20K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 20K - ₹ 30K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 30K - ₹ 50K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 50K - ₹ 75K</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">₹ 75K - ₹ 1 Lakh</Link>
                                                                                            </li>
                                                                                            <li className="menu-item">
                                                                                                <Link to="">Above ₹ 1 Lakh</Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="tab-item" id='#tab6'>
                                                                            <div className="row">
                                                                                
                                                                                <div className='col-12'>
                                                                                    <div className='daily-wear'>
                                                                                        <ul className='d-flex'>
                                                                                            <li>
                                                                                                <Link to="/allJewellery">
                                                                                                    <img src={daily1} alt='' />
                                                                                                    <h4>Rings</h4>
                                                                                                </Link>
                                                                                            </li>
                                                                                            <li>
                                                                                                <Link to="/allJewellery">
                                                                                                    <img src={daily2} alt='' />
                                                                                                    <h4>Ear Rings</h4>
                                                                                                </Link>
                                                                                            </li>
                                                                                            <li>
                                                                                                <Link to="/allJewellery">
                                                                                                    <img src={daily3} alt='' />
                                                                                                    <h4>Chains</h4>
                                                                                                </Link>
                                                                                            </li>
                                                                                            <li>
                                                                                                <Link to="/allJewellery">
                                                                                                    <img src={daily4} alt='' />
                                                                                                    <h4>Anklets</h4>
                                                                                                </Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li> */}
                                                <li className="site-logo site-logo-text">
                                                    <Link to="/">
                                                        <img src={millinewLogo} alt='Milli Logo' />
                                                    </Link>
                                                </li>
                                                <li className="menu-item menu-item-has-children menu-item-has-megamenu">
                                                    <Link to="#">
                                                        Gifting
                                                    </Link>
                                                    <div className="sub-menu">
                                                        <div className="row">
                                                            <div className='col-10 mr-0'>
                                                                <div className="d-flex">
                                                                    <ul className='col-12 d-flex'>
                                                                        <li>
                                                                            <Link to="#">
                                                                                <img src={gift1} alt='' />
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to="#">
                                                                                <img src={gift2} alt='' />
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to="#">
                                                                                <img src={gift3} alt='' />
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to="#">
                                                                                <img src={gift4} alt='' />
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="mt-3 menu-item-has-children">
                                                                <h5 className="">Gifts</h5>
                                                                <ul className="sub-menu mt-2">
                                                                    <li className="menu-item"><Link to="">Birthday Gifts</Link></li>
                                                                    <li className="menu-item"><Link to="">Anniversary Gifts</Link></li>
                                                                    <li className="menu-item"><Link to="">Self Gifts</Link></li>
                                                                    <li className="menu-item"><Link to=""> Personalized GiftsK</Link></li>

                                                                    <h6 className='mt-3'>Shop for</h6>
                                                                    <li className='menu-item mt-2'><Link to="#">Kids</Link></li>
                                                                    <li className='menu-item'><Link to="#">Sister</Link></li>
                                                                    <li className='menu-item'><Link to="#">Wife</Link></li>
                                                                    <li className='menu-item'><Link to="#">Mom</Link></li>
                                                                    <li className='menu-item'><Link to="#">Friends</Link></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="menu-item menu-item-has-children">
                                                    <Link to="/milli-harvest">
                                                        Milli Harvest
                                                    </Link>
                                                </li>
                                                <li className="menu-item menu-item-has-children">
                                                    <Link to="/store">
                                                        Shop by Store
                                                    </Link>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                                <div className="toggle">
                                    <span className="sigma-notification">0</span>
                                    <Link to="/wishlist" ><i className="fal fa-heart" /></Link>
                                </div>
                                <div className="toggle">
                                    <span className="sigma-notification">{this.state.cartCount}</span>
                                    <Link to="#" id="offCanvasBtn" onClick={this.addClass}><i className="fal fa-shopping-bag" /></Link>
                                </div>

                                {/* <div className='toggle-wrapper'>
                                   <label className="switch">
                                   <input type="checkbox" onChange={this.setToggleTheme}/>
                                   
                                   <span className="slider round"></span>
                                   </label>
                                </div> */}
                            </div>
                            {/* Site Logo */}
                            <div className="site-logo d-block d-lg-none">
                                <Link to="/">
                                    <img src={millinewLogo} alt='Milli logo' />
                                </Link>
                            </div>
                            {/* Navbar Toggler */}
                            {/* <div className="sigma-mobile-header">
                                <div className="sigma-mobile-header-inner">
                                    <Link to="/store">
                                        <i className="fal fa-store" />
                                    </Link>
                                </div>
                            </div > */}
                            <div className="sigma-mobile-header">
                                <div className="sigma-mobile-header-inner">
                                    <span className="sigma-notification">{this.state.cartCount}</span>
                                    <Link to="#" id="offCanvasBtn" onClick={this.addClass}><i className="cart fal fa-shopping-bag" /></Link>
                                </div>
                            </div >
                            <div className="sigma-mobile-header">
                                <div className="sigma-mobile-header-inner">
                                    <i className="search fal fa-search" />
                                </div>
                            </div >
                            {/* Navbar Toggler */}
                            <div className="sigma-mobile-header">
                                <div className="container">
                                    <div className="sigma-mobile-header-inner">
                                        <div className="sigma-hamburger-menu" onClick={this.toggleClass}>
                                            <div className={classNames("sigma-menu-btn", { "active": this.state.togglemethod })}>
                                                <span />
                                                <span />
                                                <span />
                                            </div>
                                        </div >
                                    </div>
                                </div>
                            </div >
                            {/* Mobile Menu Start */}
                            <aside className={classNames("sigma-mobile-menu", { "active": this.state.togglemethod })}>
                                <Mobilemenu />
                            </aside >
                            {/* Mobile Menu End */}
                        </div >
                    </div >
                </header >
                <div className={classNames("offcanvas-wrapper", { "show-offcanvas": this.state.classmethod })}>
                    <div className={classNames("offcanvas-overly", { "show-overly": this.state.classmethod })} onClick={this.removeClass} />
                    <div className="offcanvas-widget">
                        <Link to="#" className="offcanvas-close" onClick={this.removeClass} ><i className="fal fa-times" /></Link>
                        <Canvas dataControl={this.state.showCartDtl} classmethod={this.state.classmethod} />
                    </div >
                </div >
            </Fragment >
        );
    }
}

export default Headertwo;