import React, { Component, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import Pagination from '../../layouts/Pagination';
import Sidebar from '../../layouts/Shopsidebar';


class Content extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            currentPage: 1,
            totalPages: 1,
            totalResults: 0,
            filterTypeData: ''
        };
    }

    componentDidMount() {
        this.setState({ filterTypeData: '' })
        var filterPath = '';
        if (this.props.match.params.filterType != undefined) {
            let filterParams = JSON.parse(this.props.match.params.filterType);
            let arrayControl = [filterParams];
            arrayControl.forEach((element, i) => {
                const keys = Object.keys(element);
                keys.forEach((data, k) => {
                    if (k > 0) filterPath = filterPath + '&';
                    if (data == 'category' && element[data] == 'Rings') {
                        filterPath = filterPath + data + '=' + 'Fingerring';
                    } else {
                        filterPath = filterPath + data + '=' + element[data];
                    }
                });

            });
            this.setState({ filterTypeData: filterPath }, () => {
                this.getProductListByFilter();
            });

            console.log(filterPath)
        } else {
            this.fetchData();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.filterType !== prevProps.match.params.filterType) {
            var filterPath = '';
            if (this.props.match.params.filterType != undefined) {
                let filterParams = JSON.parse(this.props.match.params.filterType);
                let arrayControl = [filterParams];
                arrayControl.forEach((element, i) => {
                    const keys = Object.keys(element);
                    keys.forEach((data, k) => {
                        if (k > 0) filterPath = filterPath + '&';
                        if (data == 'category' && element[data] == 'Rings') {
                            filterPath = filterPath + data + '=' + 'Fingerring';
                        } else {
                            filterPath = filterPath + data + '=' + element[data];
                        }
                    });

                });
                this.setState({ filterTypeData: filterPath }, () => {
                    this.getProductListByFilter();
                });
            }
        }
    }

    getProductListByFilter = async (pageNumber = '') => {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL +`/api/product/productList/?${this.state.filterTypeData}`);
        const responseData = await response.json();
        if (responseData != undefined && responseData.data != undefined && responseData.data.length != undefined && responseData.data.length > 0) {
            const { data, totalPages, totalResults } = responseData;
            this.setState({ data, totalPages, totalResults });
        } else {
            this.setState({ data: [], totalPages: 0, totalResults: 0 });
        }

    }

    fetchData = async (pageNumber = '') => {
        try {
            const { min, max, page } = this.props.match.params;
            if (max) {
                const response = await fetch(process.env.REACT_APP_API_BASE_URL +`/api/product/get_product_by_price/${min}/${max}`);
                const responseData = await response.json();
                const { data, totalPages, totalResults } = responseData;
                this.setState({ data, totalPages, totalResults });
            } else {
                if (page || pageNumber) {
                    const response = await fetch(process.env.REACT_APP_API_BASE_URL +`/api/product/get_product_detail/${page ? page : pageNumber}`);
                    const responseData = await response.json();
                    const { data, totalPages, totalResults } = responseData;
                    this.setState({ data, totalPages, totalResults });
                } else {
                    const response = await fetch(process.env.REACT_APP_API_BASE_URL +'/api/product/get_product_detail');
                    const responseData = await response.json();
                    const { data, totalPages, totalResults } = responseData;
                    this.setState({ data, totalPages, totalResults });
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    handlePageChange = (page) => {
        this.setState({ currentPage: page }, () => {
            if (this.state.filterTypeData == '') {
                this.fetchData(page);
            } else {
                let filterValue = this.state.filterTypeData;
                filterValue = filterValue + '&page=' + page;
                this.setState({ filterTypeData: filterValue }, () => {
                    this.getProductListByFilter(page);
                });
            }

        });
    };

    render() {
        const { data, currentPage, totalPages, totalResults } = this.state;
        const resultsPerPage = data.length;
        const firstResultIndex = (currentPage - 1) * resultsPerPage + 1;
        const lastResultIndex = Math.min(firstResultIndex + resultsPerPage - 1, totalResults);

        return (
            <section className="Shop-section pt-120 pb-120">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-10 col-sm-10">
                            <Sidebar />
                        </div>
                        <div className="col-lg-8 col-md-10">
                            <div className="shop-products-wrapper">
                                <div className="shop-product-top">
                                    <p>Showing {firstResultIndex} to {lastResultIndex} of {totalResults} results</p>
                                    <div className="sorting-box">
                                        <select name="guests" id="guests" className="nice-select">
                                            <option value={0}>Default Sorting</option>
                                            <option value={1}>Sort By Popularity</option>
                                            <option value={2}>Sort By Latest</option>
                                            <option value={4}>Sort By Rating</option>
                                            <option value={8}>Sort By Price:Low to High</option>
                                            <option value={8}>Sort By Price:High to Low</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="product-wrapper restaurant-tab-area">
                                    <div className="row">
                                        {data.map((item, i) => (
                                            <div key={i} className="col-lg-4 col-md-6">
                                                <div className="food-box shop-box">
                                                    <div className="thumb">
                                                        <img src={item.product_parent_image} alt="" />
                                                        {/* <div className="badges">
                                                            {
                                                                item.discount > 0 || item.discount !== '' ? <span className="price">Sale</span> : ''
                                                            }
                                                            {
                                                                item.discount > 0 || item.discount !== '' ? <span className="price discounted">-{item.discount}%</span> : ''
                                                            }
                                                        </div> */}
                                                        {/* <div className="button-group">
                                                            <Link to="#"><i className="far fa-heart" /></Link>
                                                            <Link to="#"><i className="far fa-sync-alt" /></Link>
                                                            <Link to="#"><i className="far fa-eye" /></Link>
                                                        </div> */}
                                                    </div>
                                                    <div className="desc">
                                                        <h4>
                                                            <Link to={"/product-detail/" + item.product_id}>{item.product_name}</Link>
                                                        </h4>
                                                        <span className="price">Rs. {item.product_value}
                                                            {/* {item.discount > 0 || item.discount !== '' ? <span> ${Math.ceil(item.product_price * (item.discount / 100))} </span> : ''} */}
                                                        </span>
                                                        <Link to={"/product-detail/" + item.product_id} className="link"><i className="fal fa-arrow-right" /></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="pagination-wrap">
                                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={this.handlePageChange} filterTypeData = {this.props.match.params?.filterType}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default withRouter(Content);